import { ApiResponse, apiBase, checkStatus, customPost, getById } from "./utils"

const baseUrl = `${apiBase}/api/payments`

const twisPayHostname = `https://secure-stage.twispay.com`

export function postPreparePaymentData(data) {
  return customPost(`${baseUrl}/preparePaymentData`, data)
}

export function postDataToTwisPay(formData) {
  const loginData = {
    method: "post",
    body: formData,
  }
  return fetch(twisPayHostname, loginData).then(checkStatus)
}

export type PaymentData = {
  status: number
  customData: {
    _id: string
    transactionId: String
    isPlataFacturi: boolean
    tipCerere?: number
  }
}

export function getPaymentById(
  paymentId: string
): Promise<ApiResponse<PaymentData>> {
  return getById(baseUrl, paymentId)
}
