import { PageProps, navigate } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import { colors } from "../styles/globals"
import DashMenuItem from "./Dash/DashMenuItem"
import svgs from "./Dash/svgs"
import PageWithBackgroundImage from "./pageWithBackgroundImage"

type PropTypes = {
  location: PageProps["location"]
  title: string
  children?: React.ReactNode
  showDash?: boolean
  topPaneHeight?: number
  sideElement?: React.ReactNode
  additionalClassName?: string
  additionalTopPaneElement?: React.ReactNode
  additionalTitleClassName?: string
  showMobileMenu?: boolean
}
export default function TemplatePage({
  location,
  title,
  children,
  showDash = false,
  topPaneHeight = 80,
  sideElement,
  additionalClassName = "",
  additionalTopPaneElement,
  additionalTitleClassName = "",
  showMobileMenu = true,
}: PropTypes) {
  if (showDash)
    return (
      <div
        className={"templateFullContainer " + additionalClassName}
        // style={generalStyles.fullContainer}
      >
        {/* <UnderMaintenanceBanner /> */}
        <Helmet>
          <title>{`${title}`} - Gazmir.ro</title>
        </Helmet>

        <div
          className="templateDashScreenContainer"
          // style={generalStyles.screenContainer}
        >
          {showMobileMenu && (
            <div className="dashMenuTablet">
              <DashMenuItem
                containerClassName="dmtItemContainer"
                to="/app/facturi/"
                svg={svgs.facturiRestanteIcon}
                menuText="Restante"
              />
              {/* <DashMenuItem
                containerClassName="dmtItemContainer"
                to="/app/preavize/"
                svg={svgs.preavize}
                menuText="Preavize"
              /> */}
              <DashMenuItem
                containerClassName="dmtItemContainer"
                to="/app/facturi/achitate"
                svg={svgs.facturi}
                menuText="Achitate"
              />
              <DashMenuItem
                containerClassName="dmtItemContainer"
                to="/app/facturi/fisaClient"
                svg={svgs.fisaClient}
                menuText="Fișă client"
              />
              <DashMenuItem
                containerClassName="dmtItemContainer"
                to="/app/autocitire/"
                svg={svgs.autocitireIcon}
                menuText="Autocitire"
              />
              {/* <DashMenuItem
                containerClassName="dmtItemContainer"
                to="/app/revizii/"
                svg={svgs.revizii}
                menuText="Revizii"
              /> */}
            </div>
          )}
          <div
            className={
              additionalTitleClassName +
              " templateScreenTitle dashTemplateScreenTitle"
            }
            // style={generalStyles.pageTitle}
          >
            {title}
            <span
              onClick={() =>
                navigate(location?.pathname?.includes("app") ? "/app/home" : -1)
              }
              className={
                "visibleOnTabletWithFlex templateScreenCloseIcon" +
                (showMobileMenu ? " templateScreenCloseIconWithMobileMenu" : "")
              }
            >
              {svgs.closeIcon}
            </span>
          </div>
          {additionalTopPaneElement}
          <div
            className="templateScreenChildrenContainer"
            // style={{
            //   height: `calc(100% - ${topPaneHeight}px)`,
            //   // marginTop: topPaneHeight,
            //   ...generalStyles.restOfPageContainer,
            // }}
          >
            {children}
          </div>
        </div>
      </div>
    )
  else
    return (
      <PageWithBackgroundImage location={location}>
        <div className={"templateFullContainer " + additionalClassName}>
          {sideElement}
          <div
            className="templateScreenContainer"
            // style={{
            //   ...generalStyles.screenContainer,
            //   width: "70vw",
            //   marginLeft: location.pathname.includes("faq")
            //     ? "calc(-30vw + 40px)"
            //     : "-35vw",
            // }}
          >
            <div className={additionalTitleClassName + " templateScreenTitle"}>
              {title}
              <span
                onClick={() => navigate(-1)}
                className="visibleOnMobileWithFlex templateScreenCloseIcon"
              >
                {svgs.closeIcon}
              </span>
            </div>
            {additionalTopPaneElement}
            <div
              className="templateScreenChildrenContainer"
              style={{
                backgroundColor:
                  location && location.pathname.includes("faq")
                    ? colors.grayBackground
                    : colors.white,
              }}
            >
              {children}
            </div>
          </div>
        </div>
      </PageWithBackgroundImage>
    )
}
