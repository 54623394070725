import { apiBase, customPost } from "./utils"

const baseUrl = `${apiBase}/api/programariRevizii`

// export function getTarifeRevizii() {
//   return customGet(baseUrl)
// }

export function postProgramareRevizie(item) {
  return customPost(baseUrl, item)
}
// export function putNotification(item) {
//   return customPut(`${baseUrl}/${item._id}`, item)
// }

// export function putReadAll(ids) {
//   return customPut(`${baseUrl}/update/readAll`, ids)
// }

// export function putDeleteAll(ids) {
//   return customPut(`${baseUrl}/update/deleteAll`, ids)
// }
