import React, { useEffect, useState } from "react"
import svgs from "./Dash/svgs"

const styles = {
  errorStyles: {
    // backgroundColor: "#DFA3A3",
    border: "1px solid #E81010",
  },
  passwordIcon: {
    position: "absolute",
    right: 12,
    top: 6,
    width: 24,
    height: 24,
    cursor: "pointer",
  },
}

type PropTypes = {
  autoComplete?: string
  containerClassName?: string
  className?: string
  errorTextClassName?: string
  labelClassName?: string
  style?: React.CSSProperties
  type: string
  label?: string
  labelTitle?: string
  disabled?: boolean
  placeholder?: string
  name: string
  onChange: (value: string, name: string) => void
  value?: string
  isRequired?: boolean
  errorTest?: (value: string) => boolean
  errorMessage?: string
  requiredMessage?: string
  showErrorText?: boolean
  showWithoutTouched?: boolean
  maxLength?: number
  passwordIconStyle?: React.CSSProperties
  blurHook?: () => void
}

export default function FormInput({
  autoComplete,
  containerClassName = "",
  className = "",
  errorTextClassName = "",
  labelClassName = "",
  style,
  type,
  label = "",
  labelTitle,
  disabled = false,
  placeholder,
  name,
  onChange,
  value = "",
  isRequired = true,
  errorTest,
  errorMessage,
  requiredMessage = "Informație obligatorie",
  showErrorText = true,
  showWithoutTouched = false,
  maxLength = 40,
  passwordIconStyle,
  blurHook,
}: PropTypes) {
  const [touched, setTouched] = useState(false)
  const [error, setError] = useState(isRequired ? requiredMessage : "")
  const [formType, setFormType] = useState(type)

  useEffect(() => {
    if (isRequired && (!value || value === "")) setError(requiredMessage)
    else if (errorTest && errorMessage)
      if (errorTest(value)) setError("")
      else setError(errorMessage)
    else setError("")
  }, [isRequired, value, errorMessage])

  const makeFormVisible = () => {
    setFormType("text")
  }
  const makeFormInvisible = () => {
    setFormType("password")
  }

  const handleInputChange = event => {
    const { value, name } = event.target
    // if (isRequired && value === "") setError(requiredMessage)
    // else if (errorTest)
    //   if (errorTest(value)) setError("")
    //   else setError(errorMessage)
    // else setError("")
    onChange(value, name)
  }

  const handleBlur = () => {
    setTouched(true)
    if (isRequired && (!value || value === "")) setError(requiredMessage)
    else if (errorTest && errorMessage)
      if (errorTest(value)) setError("")
      else setError(errorMessage)
    else setError("")
    if (blurHook) blurHook()
  }

  // console.log(error)

  let inputStyles
  if ((touched || showWithoutTouched || (value && value.length > 0)) && error)
    inputStyles = { ...style, ...styles.errorStyles }
  else inputStyles = style
  return (
    <div
      // style={containerStyle}
      className={"cereriInputContainer " + containerClassName}
    >
      {labelTitle && <div className="inputLabelTitle">{labelTitle}</div>}
      {label && (
        <div
          className={`${
            labelTitle == null ? "inputLabel" : "inputLabelSubtitle"
          } ${labelClassName}`}
        >
          {label}
        </div>
      )}
      <input
        autoComplete={autoComplete}
        disabled={disabled}
        className={
          "cereriInput " +
          className +
          ((touched || showWithoutTouched || (value && value.length > 0)) &&
          error
            ? " cereriInputError"
            : "")
        }
        // style={inputStyles}
        type={formType}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={handleInputChange}
        onBlur={!touched ? handleBlur : undefined}
        maxLength={maxLength}
        spellCheck={false}
      ></input>
      {type === "password" && !disabled && (
        <div
          className="passwordShowIcon"
          style={{ ...passwordIconStyle }}
          onClick={
            formType === "password" ? makeFormVisible : makeFormInvisible
          }
        >
          {formType === "password" ? svgs.hidden : svgs.visible}
        </div>
      )}
      {(touched || showWithoutTouched || (value && value.length > 0)) &&
        error &&
        showErrorText && (
          <div className={"cereriInputErrorText " + errorTextClassName}>
            {error}
          </div>
        )}
    </div>
  )
}
