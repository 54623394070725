import Chartist from "chartist"
import React, { useEffect, useState } from "react"
import UsefullInfo from "../../components/Dash/UsefullInfo"
import { getConsum } from "../../utils/api/sivap"
import { getContracts } from "../../utils/services/keepAuth"
import InfoLoading from "../InfoLoading"
import TemplatePage from "../TemplatePage"
import "./style.css"

const infoItem = [
  {
    title: "Ce este Eficiența Energetică?",
    link: "/infoUtile/faqs/generale?idSelectedFaq=613a021081e473118c1b209e",
  },
  {
    title: "Instituțiile competente în domeniul eficientizării energetice.",
    link: "/infoUtile/faqs/generale?idSelectedFaq=61b1dbe04db367c6fa97d937",
  },
]

const transformConsumuri = consumuri => {
  const deReturnat = []
  const dataAzi = new Date()
  for (var i = 0; i < 12; i++) {
    dataAzi.setDate(0)
    deReturnat.push({
      luna: dataAzi.getMonth(),
      an: dataAzi.getFullYear(),
      consumMc: 0,
      consumMWh: 0,
    })
    // dataAzi.setDate(dataAzi.getDate()-1);
  }
  // console.log(deReturnat)
  // consumuri.sort(
  //   (a, b) =>
  //     new Date(a.data_sfarsit).getTime() - new Date(b.data_sfarsit).getTime
  // )

  for (i = 0; i < deReturnat.length; i++) {
    for (var j = 0; j < consumuri.length; j++)
      if (
        deReturnat[i].an ===
          new Date(consumuri[j].data_inceput).getFullYear() &&
        deReturnat[i].luna === new Date(consumuri[j].data_inceput).getMonth()
      ) {
        deReturnat[i].consumMc += consumuri[j].cantitate
        // deReturnat[i].consumMc += Math.random()
        deReturnat[i].consumMWh += consumuri[j].cantitate * consumuri[j].pcs
      }
  }
  deReturnat.sort((a, b) => a.luna - b.luna)
  return deReturnat
}

const calcConsumMediuLunar = consumuri => {
  return calcConsumUltimele12Luni(consumuri) / 12
}

const calcConsumUltimele12Luni = consumuri => {
  return consumuri.reduce((acc, item) => acc + item.consumMc, 0)
}

const getLastConsum = consumuri => {
  for (var i = consumuri.length - 1; i > -1; i--)
    if (consumuri[i].an === new Date().getFullYear()) {
      return consumuri[i].consumMc
    }
  return 0
}

export default function DateConsumPage({ location }) {
  const [clcIndex, setClcIndex] = useState(0)
  const [infoMsg, setInfoMsg] = useState(null)
  const [consumuri, setConsumuri] = useState(null)

  const [showMoreInfo, setShowMoreInfo] = useState(false)

  const toggleShowMoreInfo = () => {
    setShowMoreInfo(oldValue => !oldValue)
  }

  useEffect(() => {
    const getDateConsum = async index => {
      try {
        setInfoMsg("Vă rugăm așteptați")
        const apiResponse = await getConsum(getContracts()[clcIndex]._id)
        // console.log(apiResponse)
        setConsumuri(transformConsumuri(apiResponse.data))
        setInfoMsg(null)

        // if (Response.type) set
      } catch (error) {
        console.log(error)
        setInfoMsg("Indisponibil momentan")
      }
    }

    // console.log("use effect: ", clcIndex)
    if (clcIndex !== null) getDateConsum(clcIndex)
  }, [clcIndex])

  const projectY = (chartRect, bounds, value) => {
    if (value === 0 && bounds.min === 0) return chartRect.y1
    else
      return (
        chartRect.height() / 2 +
        chartRect.y2 -
        (chartRect.height() / (Math.abs(bounds.max) + Math.abs(bounds.min))) *
          value
      )
  }

  useEffect(() => {
    if (consumuri && consumuri.length) {
      var data = {
        // A labels array that can contain any sort of values
        labels: consumuri.map((item, index) => `${index}\n${item.consumMc}`),
        // datasets: [
        //   {
        //     data: consumuri.map(item => item.consumMc),
        //   },
        // ],
        // [
        //   "Ian "
        //   "Feb",
        //   "Mar",
        //   "Apr",
        //   "Mai",
        //   "Iun",
        //   "Iul",
        //   "Aug",
        //   "Sep",
        //   "Oct",
        //   "Nov",
        //   "Dec",
        // ],
        // Our series array that contains series objects or in this case series data arrays
        series: [consumuri.map(item => item.consumMc)],
      }
      var options = {
        width: "100%",
        height: 350,

        max: Math.max(
          1,
          Math.max(consumuri.map((item, index) => item.consumMc))
        ),
        min: Math.min(
          0,
          Math.min(consumuri.map((item, index) => item.consumMc))
        ),
        // horizontalBars:true,
        targetLineY: {
          value: calcConsumMediuLunar(consumuri),
          class: "ct-target-line",
        },
        axisY: {
          offset: 40,
          position: "start",
          labelOffset: {
            x: 0,
            y: 12,
          },
          labelInterpolationFnc: function (value) {
            // console.log("labelInterpolationFnc: " + value)
            // If the value is an integer, return it as a label. Otherwise, return null to skip this label.
            return Number.isInteger(value) ? value : null
          },
        },

        chartPadding: {
          right: 16,
          left: 16,
          bottom: 30,
          top: 16,
        },
      }
      // console.log(data.series)

      const chart = new Chartist.Bar(".ct-chart", data, options)
      chart.on("created", function (context) {
        // console.log(context.chartRect)
        // console.log(context.bounds)
        // console.log(context.options.targetLineY)
        var targetLineY = projectY(
          context.chartRect,
          context.bounds,
          context.options.targetLineY.value
        )
        context.svg.elem(
          "line",
          {
            x1: context.chartRect.x1,
            x2: context.chartRect.x2,
            y1: targetLineY,
            y2: targetLineY,
          },
          context.options.targetLineY.class
        )
      })
      chart.on("draw", context => {
        if (context.type === "label") {
          // context.element.attr({ width: "40px" })
        }
        // else if (context.type === "grid") {
        //   context.element.attr({ marginLeft: -20 })
        // }
      })
    }
  }, [consumuri])

  // console.log(consumuri)
  return (
    <TemplatePage title="Date consum" location={location} showDash>
      {/* <SelectClc
              clcIndex={clcIndex}
              selectClc={setClcIndex}
              containerStyle={generalStyles.selectClc.container}
              showHomeIcon={true}
              homeIconContainerStyle={generalStyles.selectClc.homeIcon}
              textContainerStyle={generalStyles.selectClc.textContainer}
              dropDownIconContainerStyle={generalStyles.selectClc.dropDownIcon}
              isDropDownIconDark={true}
              clcItemContainerStyle={generalStyles.selectClc.clcItemContainer}
            /> */}
      <InfoLoading infoMsg={infoMsg} />
      <div className="chartAndLegendContainer">
        {consumuri && consumuri.length && (
          <>
            <div className="legendContainer">
              <div className="legendRow">
                <div className="legendLabel">Consum mediu lunar</div>
                <div className="legendValue">
                  {consumuri ? parseInt(calcConsumMediuLunar(consumuri)) : ""}
                </div>
              </div>
              <div className="legendRow">
                <div className="legendLabel">Ultimul consum</div>
                <div className="legendValue">
                  {consumuri ? getLastConsum(consumuri) : ""}
                </div>
              </div>
              <div className="legendRow">
                <div className="legendLabel">Ultimele 12 luni</div>
                <div className="legendValue">
                  {consumuri ? calcConsumUltimele12Luni(consumuri) : ""}
                </div>
              </div>
            </div>
            <div>
              <div className="ct-chart ct-perfect-fourth"></div>
            </div>
            <div className="consumMcLabel">mc</div>
            <div className="consumMcLabel consumLuniLabel">Luni</div>
          </>
        )}

        {/* {consumuri && consumuri.length && (
          
        )} */}
        {/* <table
              className="darkTable tableFacturi"
              style={{
                textAlign: "center",
                width: "100%",
                // padding: "0px 1px",
                color: colors.main,
                borderCollapse: "collapse",
                fontSize: 18,
                tableLayout: "fixed",
                maxHeight: 500,
                overflowY: "auto",
                marginLeft: 62,
                marginRight: 20,
                // border: "1px solid " + colors.main,
              }}
            >
               <tr>
                <th>Ian</th>
                <th>Feb</th>
                <th>Mar</th>
                <th>Apr</th>
                <th>Mai</th>
                <th>Iun</th>
                <th>Iul</th>
                <th>Aug</th>
                <th>Sep</th>
                <th>Oct</th>
                <th>Nov</th>
                <th>Dec</th>
              </tr>
              <tr>
                
              </tr>
            </table> 
         )}   */}
      </div>
      <UsefullInfo
        title="Eficiență energetică"
        items={infoItem}
        expanded={true}
        toggleShowMoreInfo={toggleShowMoreInfo}
      />
    </TemplatePage>
  )
}
