import React from "react"

type PropTypes = {
  querySelector?: string
  showPopup: boolean
}
export default function userBlurredBackground({
  querySelector = ".pageWithBackground",
  showPopup,
}: PropTypes) {
  React.useEffect(() => {
    return () => {
      console.log("cleanup for userBlurredBackground")
      var dashContainer = document.querySelector(querySelector)
      // console.log(dashContainer, showPopup)
      if (dashContainer != null)
        if (showPopup) dashContainer.classList.add("blurred")
        else dashContainer.classList.remove("blurred")
    }
  })

  React.useEffect(() => {
    // console.log(querySelector)
    var dashContainer = document.querySelector(querySelector)
    console.log(dashContainer, showPopup)
    if (dashContainer != null)
      if (showPopup) dashContainer.classList.add("blurred")
      else dashContainer.classList.remove("blurred")
  }, [showPopup])
}
