import { colors, generalStyles } from "../../styles/globals"

export const cereriStyles = {
  root: {
    position: "absolute",
    maxWidth: 1440,
    minWidth: 768,
    width: "90vw",
    top: "calc(50vh - 300px)",
    left: "max(5vw, 50vw - 720px)",
    // margin: "0 auto",
    overflowY: "auto",
    padding: "10px 0",
    backgroundColor: colors.grayBackground,
  },
  row: {
    width: "100%",
    float: "left",
    minHeight: 50,
    lineHeight: "50px",
    paddingLeft: 24,
    paddingRight: 24,
  },
  textLabelStyle: {
    fontSize: "20px",
    fontWeight: "700",
    verticalAlign: "middle",
    color: colors.main,
    lineHeight: "50px",
    cursor: "pointer",
  },
  textLabelMultiline: {
    fontSize: "20px",
    verticalAlign: "middle",
    color: colors.main,
    lineHeight: "normal",
    display: "inline-block",
    fontWeight: "normal",
  },
  inputContainer: {
    ...generalStyles.inputContainer,
    // height: 24,
    width: "100%",
  },
  input: {
    ...generalStyles.input,
    height: 24,
    textAlign: "left",
    paddingLeft: 10,
    width: "100%",
  },
  inputError: {
    ...generalStyles.inputErrorMsg,
    // marginLeft: 10,
    marginTop: -10,
    marginBottom: 10,
    // width: 220,
  },

  button: {
    width: "296px",
    height: "32px",
    float: "right",
    cursor: "pointer",
    marginTop: 9,
    backgroundColor: colors.secondGray,
    borderRadius: 6,
    fontSize: "16px",
    lineHeight: "20px",
    textAlign: "center",
    color: colors.white,
  },

  fileListContainer: {
    display: "inline-block",
    textAlign: "left",
    width: "100%",
    maxHeight: 200,
    overflowY: "auto",
    paddingLeft: 24,
    backgroundColor: colors.white,
  },

  filesList: {
    display: "inline-block",
    width: "calc(100% - 24px)",
    // width: "100%",
    marginLeft: 24,
    marginBottom: 0,
    paddingTop: 8,
  },
  filesListItem: {
    height: 24,
    lineHeight: "24px",
    fontSize: "16px",
    lineHeight: "16px",
    color: "#002f49",
    marginBottom: 0,
  },
  infoMsg: {
    width: "70%",
    margin: "0 auto",
    fontSize: 20,
    textAlign: "center",
    lineHeight: "normal",
    verticalAlign: "middle",
  },
}
