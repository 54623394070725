import React, { useEffect, useState } from "react"
import { splitFacturi } from "."
import UsefullInfo from "../../../components/Dash/UsefullInfo"
import Spinner from "../../../components/Spinner"
import { colors, generalStyles } from "../../../styles/globals"
import { createObjectURL } from "../../../utils"
import { getFacturaPdfByNrFac } from "../../../utils/api/fisierFacturi"
import { getFacturi } from "../../../utils/api/sivap"
import { getContracts } from "../../../utils/services/keepAuth"
import TemplatePage from "../../TemplatePage"
import TabelAchitate from "./TabelAchitate"
import TabelRestante from "./TabelRestante"
import InfoLoading from "../../InfoLoading"

const styles = {
  tabBarButton: {
    float: "left",
    width: "calc(50% - 60px)",
    height: 56,
    marginLeft: 40,
    marginTop: 12,
    color: colors.white,
    fontSize: 32,
    lineHeight: "56px",
    textAlign: "center",
  },
}

const infoItem = [
  { title: "Modul de calcul a facturii" },
  { title: "Cum se calculează prețul de vânzae al gazelor naturale?" },
  { title: "Care este tariful de distribuție a gazelor naturale?" },
  { title: "Care este tariful de furnizare a gazelor naturale?" },
]

export default function FacturiAchitatePage({ location }) {
  const [achitate, setAchitate] = useState([])
  const [infoMsg, setInfoMsg] = useState(null)

  const [showMoreInfo, setShowMoreInfo] = useState(true)

  const toggleShowMoreInfo = () => {
    setShowMoreInfo(oldValue => !oldValue)
  }

  const seeFact = async nrFactura => {
    var windowReference = window.open()
    // console.log("TO DO SEE PDF FAC: ", nrFactura.replace("/", ""))
    try {
      // const apiResponse = await getFacturaPdfByNrFac(nrFactura);
      const apiResponse = await getFacturaPdfByNrFac(nrFactura.replace("/", ""))
      if (apiResponse.type) {
        var url = createObjectURL(apiResponse)
        // window.open(url, "_blank").focus();
        windowReference.location.href = url
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    const getFacturiPtClc = async clcIndex => {
      setInfoMsg("Vă rugăm așteptați")
      try {
        const response = await getFacturi(getContracts()[clcIndex]._id)
        if (response.type) {
          const [restante, achitate] = splitFacturi(response.data)
          setAchitate(achitate)
          setInfoMsg(null)
        } else setInfoMsg("Indisponibil")
      } catch (error) {
        console.log(error)
        setInfoMsg("Indisponibil")
      }
    }
    getFacturiPtClc(0)
  }, [])

  return (
    <TemplatePage title="Facturi achitate" location={location} showDash={true}>
      <InfoLoading infoMsg={infoMsg} />
      <TabelAchitate facturi={achitate} seeFact={seeFact} />
    </TemplatePage>
  )
}
