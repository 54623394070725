import React, { useEffect, useState } from "react"
import UsefullInfo from "../../../components/Dash/UsefullInfo"
import Spinner from "../../../components/Spinner"
import { colors, generalStyles } from "../../../styles/globals"
import { getAllPreavize } from "../../../utils/api/preavize"
import TemplatePage from "../../TemplatePage"
import TabelPreavize from "./TabelPreavize"

const styles = {
  tabBarButton: {
    float: "left",
    width: 480,
    height: 56,
    marginLeft: 40,
    marginTop: 12,
    color: colors.white,
    fontSize: 32,
    lineHeight: "56px",
    textAlign: "center",
  },
}

const infoItem = [
  {
    title: "Ce este un preaviz?",
    link: "/infoUtile/faqs/generale?idSelectedFaq=61bb14796c5c5e36f5ea5659",
  },
  {
    title: "Cum plătesc un preaviz?",
    link: "/infoUtile/faqs/generale?idSelectedFaq=61bb166e6c5c5e36f5ea565a",
  },
  {
    title: "Cum se reia furnizarea de gaze?",
    link: "/infoUtile/faqs/generale?idSelectedFaq=61bb1bf76c5c5e36f5ea565b",
  },
]

export default function PreavizePage({ location }) {
  const [preavize, setPreavize] = useState(null)
  const [infoMsg, setInfoMsg] = useState(null)

  const [showMoreInfo, setShowMoreInfo] = useState(true)

  const toggleShowMoreInfo = () => {
    setShowMoreInfo(oldValue => !oldValue)
  }

  const platestePreaviz = idPreaviz => {
    // console.log("TO DO PAY PREAVIZ: ", idPreaviz)
  }

  useEffect(() => {
    const getPreavize = async () => {
      setInfoMsg("Vă rugăm așteptați")
      try {
        const apiResponse = await getAllPreavize()
        if (apiResponse.type) {
          setPreavize(apiResponse.data)
          if (apiResponse.data.length > 5) setShowMoreInfo(false)
          setInfoMsg(null)
        } else setInfoMsg("Indisponibil")
      } catch (error) {
        console.log(error)
        setInfoMsg("Indisponibil")
      }
    }
    getPreavize()
  }, [])

  // console.log(preavize)

  return (
    <TemplatePage title="Preavize" location={location} showDash>
      {infoMsg && (
        <div
          style={{
            position: "absolute",
            top: 0,
            marginTop: 250,
            textAlign: "center",
            width: "100%",
          }}
        >
          <Spinner
            containerStyle={{
              width: 60,
              height: 60,
              margin: "0 auto",
            }}
            show={infoMsg === "Vă rugăm așteptați"}
          />
          <div
            style={{
              color: colors.main,
              marginTop: 30,
              textAlign: "center",
              fontSize: 20,
              fontWeight: 700,
            }}
          >
            {infoMsg}
          </div>
        </div>
      )}

      <div style={{}}>
        {preavize !== null && preavize.length > 0 && (
          <TabelPreavize
            preavize={preavize}
            platestePreaviz={platestePreaviz}
          />
        )}
        {preavize && preavize.length === 0 && (
          <div
            style={{
              color: colors.main,
              marginTop: 30,
              textAlign: "center",
              fontSize: 20,
              fontWeight: 700,
            }}
          >
            Nu aveți nici un preaviz activ
          </div>
        )}
      </div>
      <UsefullInfo
        title="Informații despre preavize:"
        items={infoItem}
        expanded={true}
        toggleShowMoreInfo={toggleShowMoreInfo}
      />
    </TemplatePage>
  )
}
