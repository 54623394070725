import React from "react"
import { colors } from "../../../styles/globals"
import { dateToDesiredFormat } from "../../../utils"

export default function TabelPreavize({ preavize, platestePreaviz }) {
  return (
    <table
      className="darkTable tableFacturi fixedHeader tabelRestante"
      style={{
        textAlign: "center",
        width: "100%",
        // padding: "0px 1px",
        color: colors.main,
        borderCollapse: "collapse",
        fontSize: 20,
        tableLayout: "fixed",
        maxHeight: 500,
        overflowY: "auto",
        // border: "1px solid " + colors.main,
      }}
    >
      <thead>
        <tr>
          <th>Număr preaviz</th>
          <th>Dată emitere</th>
          <th>Dată deconectare</th>
          <th>Valoarea (Lei)</th>
          <th>Rest de plată</th>
          {/* <th>Statut plată</th> */}
          <th>Plătește</th>
        </tr>
      </thead>
      <tbody>
        {preavize.map(item => (
          <tr key={item._id}>
            <td>{item.numarPreaviz}</td>
            <td>{dateToDesiredFormat(new Date(item.dataEmitere))}</td>
            <td>{dateToDesiredFormat(new Date(item.dataDeconectare))}</td>
            <td>
              {item.valoare.toLocaleString("ro-RO", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
            <td>
              {item.restDePlata.toLocaleString("ro-RO", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
            <td style={{ padding: 0 }}>
              <div
                style={{
                  cursor: "pointer",
                  margin: "16px 30px",
                  backgroundColor: colors.main,
                  borderRadius: 6,
                  height: 24,
                  color: colors.white,
                  fontSize: 16,
                  lineHeight: "24px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
                onClick={() => platestePreaviz(item._id)}
              >
                Plătește
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
