import React, { useEffect, useState } from "react"

type PropTypes = {
  containerClassName?: string
  className?: string
  labelClassName?: string
  disabled?: boolean
  label?: string
  labelTitle?: string
  placeholder?: string
  name: string
  value: string
  onChange: (value: string, name: string) => void
  isRequired?: boolean
  errorTest?: (value: string) => boolean
  errorMessage?: string
  requiredMessage?: string
  errorClassName?: string
  showErrorText?: boolean
  maxLength?: number
  showWithoutTouched?: boolean
}
export default function NewTextArea({
  containerClassName = "",
  className = "",
  labelClassName = "",
  disabled = false,
  placeholder,
  label,
  labelTitle,
  name,
  value,
  onChange,
  isRequired = true,
  errorTest,
  errorMessage = "Informație invalidă",
  requiredMessage = "Informație obligatorie",
  errorClassName = "",
  showErrorText = true,
  maxLength = 500,
  showWithoutTouched = false,
}: PropTypes) {
  const [touched, setTouched] = useState(false)
  const [error, setError] = useState(isRequired ? requiredMessage : "")

  useEffect(() => {
    if (isRequired && (!value || value === "")) setError(requiredMessage)
    else if (errorTest)
      if (errorTest(value)) setError("")
      else setError(errorMessage)
    else setError("")
  }, [isRequired, value, errorMessage])

  const handleInputChange = event => {
    const { value, name } = event.target
    // if (isRequired && value === "") setError(requiredMessage)
    // else if (errorTest)
    //   if (errorTest(value)) setError("")
    //   else setError(errorMessage)
    // else setError("")
    onChange(value, name)
  }

  const handleBlur = () => {
    setTouched(true)
    if (isRequired && (!value || value === "")) setError(requiredMessage)
    else if (errorTest)
      if (errorTest(value)) setError("")
      else setError(errorMessage)
  }

  return (
    <div className={"newTextAreaContainer " + containerClassName}>
      {labelTitle && <div className="inputLabelTitle">{labelTitle}</div>}
      {label && (
        <div
          className={`${
            labelTitle == null ? "inputLabel" : "inputLabelSubtitle"
          } ${labelClassName}`}
        >
          {label}
        </div>
      )}
      <textarea
        // lock
        disabled={disabled}
        className={
          "newTextArea " +
          className +
          ((touched || showWithoutTouched) && error ? " textAreaError" : "")
        }
        // style={{ ...inputStyles, resize: "none" }}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={handleInputChange}
        onBlur={!touched ? handleBlur : undefined}
        maxLength={maxLength}
        spellCheck={false}
      ></textarea>
      {(touched || showWithoutTouched) && error && showErrorText && (
        <div className={"textAreaErrorText " + errorClassName}>{error}</div>
      )}
    </div>
  )
}
