import React from "react"
import svgs from "../../Dash/svgs"
import TextArea from "../../TextArea"
import FormInput from "../../FormInput"
import { colors, generalStyles } from "../../../styles/globals"
import NewTextArea from "../../CereriNew/NewTextArea"

type PropTypes = {
  placeholder: string
  type: string
  name: string
  onChange?: (value: string, name: string) => void
  value: string
  errorTest?: (value: string) => boolean
  errorMessage?: string
  disabled?: boolean
}

const styles = {
  container: { padding: "80px 80px" },
  row: {
    display: "flex",
    minHeight: 40,
    alignItems: "center",
  },
  rowLabel: {
    // float: "left",
    flex: 1,
    fontSize: 20,
    fontWeight: "bold",
    color: colors.main,
    lineHeight: "normal",
  },
  rowInput: {
    // float: "left",
    flex: 1,
    fontSize: 20,
    color: colors.main,
    lineHeight: "30px",
  },
  editIcon: {
    width: 24,
    height: 24,
    marginLeft: "2vw",
    cursor: "pointer",
  },
  inputError: {
    ...generalStyles.inputErrorMsg,
    float: "left",
    width: "100%",
    marginLeft: 0,
    height: 30,
  },
  submitButton: {
    width: 380,
    height: 50,
    display: "block",
    margin: "0 auto",
    backgroundColor: colors.main,
    borderRadius: 8,
    color: colors.white,
    cursor: "pointer",
    fontSize: 20,
  },
}

export default function PageInput({
  placeholder,
  type,
  name,
  onChange,
  value,
  errorTest,
  errorMessage,
  disabled = true,
}: PropTypes) {
  if (type !== "checkbox")
    if (type === "textarea")
      return (
        <NewTextArea
          containerClassName="newStyleInput"
          className="cereriInput capitalizeText"
          disabled={disabled}
          placeholder={placeholder}
          name={name}
          onChange={onChange ?? (() => {})}
          value={value}
          errorTest={errorTest}
          errorMessage={errorMessage}
          showErrorText={false}
          isRequired={false}
          maxLength={100}
        />
      )
    else
      return (
        <FormInput
          containerClassName="newStyleInput"
          className={
            " " + (placeholder === "Nume și prenume" ? " capitalizeText" : "")
          }
          disabled={disabled}
          type={type}
          placeholder={placeholder}
          name={name}
          onChange={onChange ?? (() => {})}
          value={value}
          errorTest={errorTest}
          errorMessage={errorMessage}
          showErrorText={false}
          isRequired={false}
        />
      )
  else
    return (
      <div className="switch">
        <input
          type="checkbox"
          checked={value === "true"}
          disabled={disabled}
          // onChange={onChange}
        />

        <span
          className="slider"
          style={{ cursor: disabled ? "default" : "pointer" }}
          onClick={
            disabled || onChange == null
              ? undefined
              : () => onChange("" + !value, name)
          }
        >
          {/* {value ? ( */}
          <span
            style={{ opacity: value === "true" ? 1 : 0 }}
            className="checkBoxIcon leftCheckBoxAlignment"
          >
            {svgs.thickIconCheckBox}
          </span>
          {/* ) : ( */}
          <span
            style={{ opacity: value === "true" ? 0 : 1 }}
            className="checkBoxIcon rightCheckBoxAlignment"
          >
            {svgs.closeIconCheckBox}
          </span>
          {/* )} */}
        </span>
      </div>
    )
}
