export const EMAIL_REGEX = value =>
  //eslint-disable-next-line
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
    value
  )

export const NUMERIC_REGEX = value => new RegExp("^\\d+$").test(value)

export const NUMERIC_ERROR = "Introduceți un număr întreg"

export const ALFA_NUMERIC_REGEX = value =>
  new RegExp('^[^-\\s][A-zăîâțșÂȚȘĂÎÀ-ÿ0-9\\s,.:șțȘȚ&"-/@\\–]*$').test(value)

export const ALFA_NUMERIC_ERROR = "Introduceți doar caractere alfa-numerice"

export const EMAIL_ERROR = "Introduceți o adresă de email validă"

export const PASSWORD_REGEX = value => /^(?=.*\d).{8,}$/.test(value)

export const PASSWORD_ERROR =
  "Parolă trebuie să conțină să fie de cel puțin 8 caractere și să aibă cel puțin un număr"

export const CONFIRM_PASSWORD_ERROR = "Parola introdusă nu este identică"

//eslint-disable-next-line
export const AMOUNT_REGEX = value => /^\d+(\,\d{1,2})?$/.test(value)

export const AMOUNT_ERROR = "Introduceți un număr de forma (123 sau 123.45)"

export const PHONENUMBER_REGEX = value =>
  /^(07[0-9]{2}|02[0-9]{2}|03[0-9]{2}){1}?([0-9]{3}){2}$/.test(value)

export const PHONENUMBER_ERROR = "Introduceți un număr de forma 07xxxxxxxx"

export const CNP_REGEX = value => value.length === 13 && NUMERIC_REGEX(value)
export const CNP_ERROR = "Introduceți un cnp valid din 13 cifre"

export const VALIDATION_CODE_REGEX = value =>
  NUMERIC_REGEX(value) && value.length === 6

export const VALIDATION_CODE_ERROR =
  "Introduceți codul de 6 cifre primit pe e-mail"
