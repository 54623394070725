import React from "react"

export default function useFixedTooltip(elementRef, padding) {
  const onMouseEnter = event => {
    if (elementRef.current) {
      console.log(event.currentTarget.getBoundingClientRect())
      console.log(event.target.getBoundingClientRect())
      var x = event.currentTarget.getBoundingClientRect().left,
        y = event.currentTarget.getBoundingClientRect().top
      elementRef.current.style.top = y + padding.y + "px"
      elementRef.current.style.left = x + padding.x + "px"
    }
  }

  return onMouseEnter
}
