import React from "react"
import { checkIsFacturaCurenta, dateToDesiredFormat } from "../../../utils"
import TabelActionCell from "./TabelActionCell"
import "./style.css"
export default function TabelAchitate({ facturi, seeFact }) {
  if (facturi.length > 0)
    return (
      <table
        className="darkTable tableFacturi fixedHeader tableAchitate tabelStyle"
        // style={{
        //   textAlign: "center",
        //   width: "100%",
        //   // padding: "0px 1px",
        //   color: colors.main,
        //   borderCollapse: "collapse",
        //   fontSize: 20,
        //   tableLayout: "fixed",
        //   maxHeight: 500,
        //   overflowY: "auto",
        //   // border: "1px solid " + colors.main,
        // }}
      >
        <thead>
          <tr>
            <th>Număr factură</th>
            <th>Dată emitere</th>
            <th>Valoare (Lei)</th>
            {/* <th>Statut plată</th> */}
            <th>Descarcă</th>
          </tr>
        </thead>
        <tbody>
          {facturi.map(item => (
            <tr key={item.id_factura}>
              <td>
                {item.nr_factura.split("/")[1]
                  ? item.nr_factura.split("/")[1]
                  : item.nr_factura.split("/")[0]}
              </td>
              <td>{dateToDesiredFormat(new Date(item.data_fact))}</td>
              <td>
                {item.total_factura.toLocaleString("ro-RO", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </td>
              {/* <td>{"Achitat"}</td> */}
              <TabelActionCell
                hasPdf={item.hasPdfFactura}
                onClick={
                  item.hasPdfFactura
                    ? () => seeFact(item.nr_factura)
                    : undefined
                }
                isFacturaCurenta={checkIsFacturaCurenta(item.data_fact)}
              />
            </tr>
          ))}
        </tbody>
      </table>
    )
  else return null
}
