import React, { useRef, useState } from "react"
import svgs from "../../../components/Dash/svgs"
import FormInput from "../../../components/FormInput"
import { colors, generalStyles } from "../../../styles/globals"
import { postProgramareRevizie } from "../../../utils/api/programariRevizii"
import { PHONENUMBER_ERROR, PHONENUMBER_REGEX } from "../../../utils/regex"
import { getContracts } from "../../../utils/services/keepAuth"
import PaymentForm from "../../PaymentForm"

const styles = {
  modalContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 1,
  },
  loginLogoContainer: {
    width: 20,
    height: 20,
    top: 16,
    right: 24,
    position: "absolute",
    cursor: "pointer",

    fill: colors.main,
    // backgroundColor: "red",
  },
  loginContainer: {
    position: "absolute",
    top: "50vh",
    left: "50vw",
    width: 480,
    minHeight: 520,
    marginLeft: -260,
    marginTop: -240,
    backgroundColor: colors.loginModalBackground,
    textAlign: "center",
    padding: "0px 50px",
  },
  title: {
    height: 64,
    width: 200,
    margin: "24px auto 0px auto",
    color: colors.main,
    fontSize: 28,
    fontWeight: "bold",
    lineHeight: "32px",
  },
  row: {
    width: "100%",
    height: 24,
    marginTop: 24,
    // backgroundColor: "red",
  },
  rowText: {
    float: "left",
    height: 24,
    width: 300,
    color: colors.main,
    fontSize: 20,
    lineHeight: "24px",
    textAlign: "left",
  },
  rowInput: {
    float: "left",
    height: 24,
    width: 80,
    fontSize: 18,
    paddingLeft: 14,
  },
  nrPuncteInput: {
    // margin: 0,
    padding: "0 0 0 4px",
    width: 36,
    height: 24,
    color: colors.main,
    float: "left",
  },
  loginButton: {
    width: 160,
    height: 50,
    backgroundColor: colors.main,
    // marginLeft: 80,
    marginTop: 24,
    color: colors.white,
    fontSize: 20,
    lineHeight: "22px",
    textAlign: "center",
    border: "none",
    borderRadius: 6,
    cursor: "pointer",
  },
}

const dataLunaUrmatoare = new Date()
dataLunaUrmatoare.setMonth(dataLunaUrmatoare.getMonth() + 1)

export default function ProgramareRevizie({
  revizieProgramare,
  tarifUnitar,
  hideModal,
  goToPay,
  setShowPaymentRedirectLoading,
}) {
  const [formData, setFormData] = useState({
    nrPuncte: 1,
    data: dataLunaUrmatoare,
    interval: "1",
    telefon: "",
  })

  const formRef = useRef()
  const jsonRequestRef = useRef()
  const checksumRef = useRef()

  const handleTelefonChange = (value, name) => {
    // console.log(value, name)
    setFormData(oldFormData => ({
      ...oldFormData,
      [name]: value,
    }))
  }

  const changeNrPuncte = event => {
    event.persist()
    // console.log(event.target.value)
    setFormData(oldFormData => ({
      ...oldFormData,
      nrPuncte: event.target.value,
    }))
  }

  const changeZi = event => {
    event.persist()
    setFormData(oldFormData => {
      const newDate = new Date(oldFormData.data)
      newDate.setDate(event.target.value)
      return {
        ...oldFormData,
        data: newDate,
      }
    })
  }
  const changeLuna = event => {
    event.persist()
    setFormData(oldFormData => {
      const newDate = new Date(oldFormData.data)
      newDate.setMonth(event.target.value - 1)
      return {
        ...oldFormData,
        data: newDate,
      }
    })
  }
  const changeInterval = event => {
    event.persist()
    setFormData(oldFormData => ({
      ...oldFormData,
      interval: event.target.value,
    }))
  }

  const sendToPay = async () => {
    try {
      const { tipActivitate, dataExpirarii } = revizieProgramare
      if (tipActivitate) {
        const apiResponse = await postProgramareRevizie({
          clc: getContracts()[0].clc,
          tipActivitate,
          dataExpirarii,
          telefon: formData.telefon,
          dataDorita: formData.data,
          intervalOrarDorit: formData.interval,
          nrAparate: formData.nrPuncte,
          tarifUnitar: tarifUnitar,
        })
        if (apiResponse.type) {
          // console.log(apiResponse)
          jsonRequestRef.current.value = apiResponse.data.base64JsonRequest
          checksumRef.current.value = apiResponse.data.base64Checksum
          setShowPaymentRedirectLoading(true)
          formRef.current.submit()
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  // console.log(formData)
  if (revizieProgramare)
    return (
      <div
        style={styles.modalContainer}
        // onClick={toggleLoginModal}
      >
        <div
          style={styles.loginContainer}
          // onClick={undefined}
        >
          <div
            role="button"
            tabIndex={0}
            style={styles.loginLogoContainer}
            onClick={hideModal}
          >
            {/* <img alt="close-icon" src="/closeIcon.svg" style={{ width: 15 }} /> */}
            {svgs.closeIcon}
          </div>
          <div style={styles.title}>{`Programare ${
            revizieProgramare.tipActivitate === 1
              ? "Revizie I.U."
              : "V.T.P. I.U."
          }`}</div>
          <div style={styles.row}>
            <div style={styles.rowText}>Nr. puncte de ardere (Lei):</div>
            <div style={styles.rowInput}>
              <input
                style={{ ...styles.nrPuncteInput, width: 66 }}
                type="number"
                min="1"
                id="nrPuncte"
                name="nrPuncte"
                onChange={changeNrPuncte}
                value={formData.nrPuncte}
              />
            </div>
          </div>
          <div style={styles.row}>
            <div style={styles.rowText}>Tarif per punct de ardere (Lei):</div>
            <div style={styles.rowInput}>
              <div
                style={{
                  height: 24,
                  padding: "0px 10px",
                  backgroundColor: colors.white,
                  color: colors.main,
                  fontSize: 18,
                  lineHeight: "24px",
                }}
              >
                {tarifUnitar}
              </div>
            </div>
          </div>
          <div style={{ ...styles.row, marginTop: 44 }}>
            <div style={{ ...styles.rowText, width: "25%" }}>Ziua:</div>
            <div style={{ ...styles.rowText, width: "25%" }}>Luna:</div>
            <div
              style={{ ...styles.rowText, width: "50%", textAlign: "right" }}
            >
              Intervalul orar dorit:
            </div>
          </div>
          <div style={{ ...styles.row }}>
            <div style={{ ...styles.rowText, width: "25%" }}>
              <div style={{ ...styles.rowInput, paddingLeft: 0 }}>
                <input
                  style={styles.nrPuncteInput}
                  value={formData.data.getDate()}
                  onChange={changeZi}
                  type="number"
                  id="ziProgramare"
                  name="ziProgramare"
                />
              </div>
            </div>
            <div style={{ ...styles.rowText, width: "25%" }}>
              <div style={{ ...styles.rowInput, paddingLeft: 0 }}>
                <input
                  value={formData.data.getMonth() + 1}
                  onChange={changeLuna}
                  style={{ ...styles.nrPuncteInput, margin: "0 auto" }}
                  type="number"
                  min="1"
                  max="12"
                  id="lunaProgramare"
                  name="lunaProgramare"
                />
              </div>
            </div>
            <div
              style={{ ...styles.rowText, width: "50%", textAlign: "right" }}
            >
              <div style={{ ...styles.rowInput, marginLeft: 30 }}>
                <select
                  value={formData.interval}
                  onChange={changeInterval}
                  style={{
                    width: 120,
                    height: 24,
                    border: "none",
                    color: colors.main,
                    fontSize: 18,
                    lineHeight: "24px",
                  }}
                  name="intervalProgramare"
                  id="intervalProgramare"
                >
                  <option value="1">08:00-10:00</option>
                  <option value="2">10:00-12:00</option>
                  <option value="3">12:00-14:00</option>
                  <option value="4">14:00-16:00</option>
                </select>
              </div>
            </div>
          </div>
          <div style={{ ...styles.row }}>
            <FormInput
              containerStyle={{
                ...generalStyles.inputContainer,
                height: 24,
              }}
              style={{
                ...generalStyles.input,
                height: 24,
                textAlign: "center",
              }}
              errorStyle={{ ...generalStyles.inputErrorMsg, marginLeft: 0 }}
              type="tel"
              placeholder="Telefon"
              name="telefon"
              onChange={handleTelefonChange}
              value={formData.telefon}
              errorTest={PHONENUMBER_REGEX}
              errorMessage={PHONENUMBER_ERROR}
            />
          </div>
          <div style={styles.row}>
            <div style={{ ...styles.rowText, width: 280 }}>
              Total sumă de achitat (Lei):
            </div>
            <div style={{ ...styles.rowInput, paddingLeft: 4, width: 100 }}>
              <div
                style={{
                  height: 24,
                  padding: "0px 10px",
                  backgroundColor: colors.white,
                  color: colors.main,
                  fontSize: 18,
                  lineHeight: "24px",
                }}
              >
                {(formData.nrPuncte * tarifUnitar).toLocaleString("ro-RO", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </div>
            </div>
          </div>
          <div>
            <PaymentForm
              formRef={formRef}
              jsonRequestRef={jsonRequestRef}
              checksumRef={checksumRef}
              // buttonClassName="buttonHover"
              buttonStyles={styles.loginButton}
              disabled={!PHONENUMBER_REGEX(formData.telefon)}
              buttonLabel="Plătește și finalizează"
              makePayment={sendToPay}
            />
          </div>
        </div>
      </div>
    )
  else return false
}
