import { RouteComponentProps } from "@reach/router"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Button from "../../Button"
import DashMenuItem from "../../Dash/DashMenuItem"
import svgs from "../../Dash/svgs"
import "./styles.css"
export default function ContFaraDatePage({}: RouteComponentProps) {
  return (
    <div id="contFaraDatePage" className="templateFullContainer ">
      <div className="homePageContainer visibleOnTabletWithFlex">
        {/* <InfoClient
          containerClassName="icMobile"
          setShowPaymentRedirectLoading={setShowPaymentRedirectLoading}
        /> */}

        <div className="cfdMobileHeader">
          <div className="title">
            Contul tău a fost activat însă nu avem informații de afișat
          </div>
          <div className="text">
            Vei putea accesa toate funcționalitățile contului tău după ce se va
            emite prima factură de cosnum. Te vom anunța printr-un email când
            acest lucru se va întâmpla.
          </div>
          <Button
            as="Link"
            aspect="secondary"
            to="/"
            label="Catre site public"
          />
        </div>
        <div className="dashboardPageMenu">
          <div className="dashboardPageMenuRow">
            <DashMenuItem
              containerClassName="dpmItemContainer"
              to="/app/facturi/"
              svg={svgs.facturiRestanteIcon}
              menuText="Restante"
            />
            <DashMenuItem
              containerClassName="dpmItemContainer"
              to="/app/facturi/achitate"
              svg={svgs.facturi}
              menuText="Achitate"
            />
            <DashMenuItem
              containerClassName="dpmItemContainer"
              to="/contact/"
              svg={svgs.contactIcon}
              menuText="Contact"
            />
          </div>
          <div className="dashboardPageMenuRow">
            <DashMenuItem
              containerClassName="dpmItemContainer"
              to="/app/facturi/fisaClient"
              svg={svgs.fisaClient}
              menuText="Fișă client"
            />
            <DashMenuItem
              containerClassName="dpmItemContainer"
              to="/app/dateConsum/"
              svg={svgs.dateConsum}
              menuText="Consum"
            />
            <DashMenuItem
              containerClassName="dpmItemContainer"
              to="/app/autocitire/"
              svg={svgs.autocitireIcon}
              menuText="Autocitire"
            />
          </div>
        </div>

        {/* <div className="dashboardAutoCitire">
        <AutoCitire />
      </div> */}
      </div>
      <div className="cfdpContent popupContainer">
        <StaticImage
          className="logo"
          loading="eager"
          layout="fullWidth"
          src="../../../images/logoNou.png"
          alt="Logo gazmir"
        />
        <div className="text">
          <div className="popupTitle">
            Contul tău a fost activat însă nu avem informații de afișat
          </div>
          <div className="text">
            Vei putea accesa toate funcționalitățile contului tău după ce se va
            emite prima factură de cosnum. Te vom anunța printr-un email când
            acest lucru se va întâmpla.
          </div>
        </div>
        <Button as="Link" aspect="primary" to="/" label="Catre site public" />
      </div>
    </div>
  )
}
