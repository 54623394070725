import React from "react"
import { Helmet } from "react-helmet"
import DashMenuItem from "../Dash/DashMenuItem"
import InfoClient from "../Dash/InfoClient"
import svgs from "../Dash/svgs"
import "./style.css"

export default function HomeDashPage({ setShowPaymentRedirectLoading }) {
  return (
    <div className="homePageContainer visibleOnTabletWithFlex">
      <Helmet>
        <title>Contul meu - Gazmir.ro</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="robots" content="index" />
        <meta
          name="description"
          content="Conecteaza-te pe Gazmir.ro si beneficiaza de Plati online, Sold la zi, Istoric plati, Istoric consum, Autocitire online, Factura electronica, Programari."
        />
        <meta
          name="keywords"
          content="Plata online gazmir,gazmir cont,cont online gazmir,contul meu gazmir,Gazmir contul meu,Conectare gazmir,Autentificare gazmir,Gazmir.ro,creere cont gazmir,cont platforma gazmir,plata facturi gazmir,autocitire gazmir,gazmir autocitire,Consum gazmir,Factura gazmir,preaviz gazmir,notificare gazmir,transmite index gazmir,fisa client gazmir"
        />
      </Helmet>
      <InfoClient
        containerClassName="icMobile"
        setShowPaymentRedirectLoading={setShowPaymentRedirectLoading}
      />

      <div className="dashboardPageMenu">
        <div className="dashboardPageMenuRow">
          <DashMenuItem
            containerClassName="dpmItemContainer"
            to="/app/facturi/"
            svg={svgs.facturiRestanteIcon}
            menuText="Restante"
          />
          <DashMenuItem
            containerClassName="dpmItemContainer"
            to="/app/facturi/achitate"
            svg={svgs.facturi}
            menuText="Achitate"
          />
          <DashMenuItem
            containerClassName="dpmItemContainer"
            to="/contact/"
            svg={svgs.contactIcon}
            menuText="Contact"
          />
        </div>
        <div className="dashboardPageMenuRow">
          <DashMenuItem
            containerClassName="dpmItemContainer"
            to="/app/facturi/fisaClient"
            svg={svgs.fisaClient}
            menuText="Fișă client"
          />
          <DashMenuItem
            containerClassName="dpmItemContainer"
            to="/app/dateConsum/"
            svg={svgs.dateConsum}
            menuText="Consum"
          />
          <DashMenuItem
            containerClassName="dpmItemContainer"
            to="/app/autocitire/"
            svg={svgs.autocitireIcon}
            menuText="Autocitire"
          />
        </div>
      </div>

      {/* <div className="dashboardAutoCitire">
        <AutoCitire />
      </div> */}
    </div>
  )
}
