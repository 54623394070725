import React from "react"
import svgs from "../Dash/svgs"
import { InfoMsgType } from "../InfoMsg"

type PropTypes = {
  infoMsg?: InfoMsgType
}

function getIconForStatus(status: InfoMsgType["status"]) {
  switch (status) {
    case "ok":
      return svgs.circleThickIcon
    case "error":
      return svgs.circleErrorIcon
    default:
      return null
  }
}

export default function PopupStatusIcon({ infoMsg }: PropTypes) {
  if (infoMsg != null && getIconForStatus(infoMsg.status) != null) {
    return <div className="statusIcon">{getIconForStatus(infoMsg.status)}</div>
  } else return null
}
