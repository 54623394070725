import React, { useEffect, useRef, useState } from "react"
import DatePicker from "react-date-picker"
import svgs from "../../../components/Dash/svgs"
import FormInput from "../../../components/FormInput"
import Spinner from "../../../components/Spinner"
import { colors, generalStyles } from "../../../styles/globals"
import { isUserCasnic } from "../../../utils"
import { postDovadaRevizie } from "../../../utils/api/doveziRevizii"
import { ALFA_NUMERIC_ERROR, ALFA_NUMERIC_REGEX } from "../../../utils/regex"
import { getContracts, getUserInfo } from "../../../utils/services/keepAuth"

const styles = {
  modalContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 1,
  },
  loginLogoContainer: {
    width: 20,
    height: 20,
    top: 16,
    right: 24,
    position: "absolute",
    cursor: "pointer",
    fill: colors.main,
    // backgroundColor: "red",
  },
  loginContainer: {
    position: "absolute",
    top: "50vh",
    left: "50vw",
    width: 480,
    minHeight: 520,
    marginLeft: -260,
    marginTop: -240,
    backgroundColor: colors.loginModalBackground,
    textAlign: "center",
    padding: "0px 50px",
  },
  title: {
    height: 64,
    margin: "24px auto 0px auto",
    color: colors.main,
    fontSize: 28,
    fontWeight: "bold",
    lineHeight: "32px",
  },
  row: {
    width: "100%",
    height: 24,
    marginTop: 36,
    // backgroundColor: "red",
  },
  rowText: {
    float: "left",
    height: 24,
    width: 160,
    color: colors.main,
    fontSize: 20,
    lineHeight: "24px",
    textAlign: "left",
  },
  rowInput: {
    float: "left",
    height: 24,
    width: 220,
    fontSize: 18,
  },
  nrPuncteInput: {
    // margin: 0,
    padding: "0 0 0 4px",
    width: 36,
    height: 24,
    color: colors.main,
    float: "left",
  },
  loginButton: {
    width: 160,
    height: 50,
    backgroundColor: colors.main,
    // marginLeft: 80,
    marginTop: 24,
    color: colors.white,
    fontSize: 20,
    lineHeight: "22px",
    textAlign: "center",
    border: "none",
    borderRadius: 6,
    cursor: "pointer",
  },
}

export default function IncarcaDovadaRevizie({ revizieDovada, hideModal }) {
  const [formData, setFormData] = useState({
    tipActivitate: 1,
    verificator: "",
    dataEfectuarii: undefined,
    fisiereDovada: [],
  })

  const [infoMsg, setInfoMsg] = useState(null)

  const inputFileRef = useRef(null)
  const timeOutRef = useRef(null)

  useEffect(() => {
    return () => {
      if (timeOutRef.current) clearTimeout(timeOutRef.current)
    }
  }, [])

  const changeTipActivitatea = event => {
    // event.persist()
    const { value } = event.target
    setFormData(oldFormData => ({
      ...oldFormData,
      tipActivitate: value,
    }))
  }

  const handleVerificatorChange = (value, name) => {
    // console.log(value, name)
    setFormData(oldFormData => ({
      ...oldFormData,
      [name]: value,
    }))
  }

  const changeDataEfectuarii = newDate => {
    setFormData(oldFormData => ({
      ...oldFormData,
      dataEfectuarii: newDate,
    }))
  }

  const checkFormIsComplete = ({
    verificator,
    dataEfectuarii,
    fisiereDovada,
  }) =>
    ALFA_NUMERIC_REGEX(verificator) &&
    verificator.length > 0 &&
    dataEfectuarii &&
    fisiereDovada.length > 0

  const addFiles = () => {
    inputFileRef.current.click()
  }

  const sendDovada = async () => {
    setInfoMsg("Vă rugăm așteptați")
    const data = new FormData()
    for (var i in formData.fisiereDovada)
      data.append("file", formData.fisiereDovada[i])
    data.append("tipActivitate", formData.tipActivitate)
    data.append("verificator", formData.verificator)
    data.append("dataEfectuarii", formData.dataEfectuarii)
    data.append("clc", getContracts()[0].clc)
    data.append("isCasnic", isUserCasnic(getUserInfo()))
    try {
      const apiResponse = await postDovadaRevizie(data)
      // console.log(apiResponse)
      if (apiResponse.filesToLarge)
        setInfoMsg(
          "A apărut o eroare la încarcarea fișierelor. Acestea nu trebuie să depășească 50MB"
        )
      else if (apiResponse.type) {
        setInfoMsg(
          "Analizăm documentele depuse. Rezultatul va fi comunicat  în cel mai scurt timp posibil."
        )
        setFormData({
          tipActivitate: 1,
          verificator: "",
          dataEfectuarii: undefined,
          fisiereDovada: [],
        })
        timeOutRef.current = setTimeout(() => {
          setInfoMsg(null)
          hideModal()
        }, 2000)
      } else
        setInfoMsg(
          "A apărut o eroare la salvarea datelor. Te rugăm să reîncerci sau să ne contactezi pentru a remedia problema"
        )
    } catch (error) {
      console.log(error)
      setInfoMsg(
        "A apărut o eroare la salvarea datelor. Te rugăm să reîncerci sau să ne contactezi pentru a remedia problema"
      )
    }
  }

  const changedSelectedFiles = event => {
    const { files } = event.target
    // console.log(files)
    // for (var i in event.target.files) console.log(typeof event.target.files[i])
    setFormData(oldFormData => {
      for (var i in files)
        if (typeof files[i] === "object")
          oldFormData.fisiereDovada.push(files[i])
      return {
        ...oldFormData,
      }
    })
  }

  const removeFile = (fileName, fileIndex) => {
    setFormData(oldFormData => {
      const deReturnat = oldFormData.fisiereDovada.filter(
        (item, index) => item.name !== fileName || index !== fileIndex
      )
      return {
        ...oldFormData,
        fisiereDovada: deReturnat,
      }
    })
  }

  // console.log(formData)
  if (revizieDovada)
    return (
      <div
        style={styles.modalContainer}
        // onClick={toggleLoginModal}
      >
        <div
          style={styles.loginContainer}
          // onClick={undefined}
        >
          {(infoMsg === null || infoMsg.includes("eroare")) && (
            <>
              <div
                role="button"
                tabIndex={0}
                style={styles.loginLogoContainer}
                onClick={hideModal}
              >
                {/* <img alt="close-icon" src="/closeIcon.svg" style={{ width: 15 }} /> */}
                {svgs.closeIcon}
              </div>
              <div
                style={styles.title}
              >{`Încarcă dovada efectuării V.T.P./Revizie`}</div>
              <div style={styles.row}>
                <div style={styles.rowText}>Tip activitate:</div>
                <div style={styles.rowInput}>
                  <select
                    value={formData.tipActivitate}
                    onChange={changeTipActivitatea}
                    style={{
                      width: 220,
                      height: 24,
                      border: "none",
                      color: colors.main,
                      fontSize: 18,
                      lineHeight: "24px",
                    }}
                    name="intervalProgramare"
                    id="intervalProgramare"
                  >
                    <option value={1}>Revizie tehnică centrală</option>
                    <option value={2}>Revizie tehnică I.U.</option>
                    <option value={3}>V.T.P. I.U.</option>
                    <option value={4}>V.T.P. Centrală</option>
                  </select>
                </div>
              </div>

              <div style={styles.row}>
                <div style={styles.rowText}>Verificator:</div>
                <div style={styles.rowInput}>
                  <FormInput
                    containerStyle={{
                      ...generalStyles.inputContainer,
                      width: 220,
                      height: 24,
                    }}
                    style={{
                      ...generalStyles.input,
                      width: 220,
                      height: 24,
                      textAlign: "center",
                      paddingLeft: 0,
                    }}
                    errorStyle={{
                      ...generalStyles.inputErrorMsg,
                      width: 220,
                      marginLeft: 0,
                    }}
                    placeholder="Verificator"
                    name="verificator"
                    onChange={handleVerificatorChange}
                    value={formData.verificator}
                    errorTest={ALFA_NUMERIC_REGEX}
                    errorMessage={ALFA_NUMERIC_ERROR}
                  />
                </div>
              </div>
              <div style={styles.row}>
                <div style={styles.rowText}>Data efectuării:</div>
                <div style={styles.rowInput}>
                  <DatePicker
                    className="gazmirDatePicker"
                    onChange={changeDataEfectuarii}
                    value={formData.dataEfectuarii}
                    clearIcon={null}
                    maxDate={new Date()}
                  />
                </div>
              </div>
              <div style={{ ...styles.row }}>
                <div style={styles.rowText}>Încarcă dovada</div>
                <div
                  style={{
                    ...styles.rowInput,
                    height: 100,
                    overflowY: "auto",
                  }}
                >
                  <input
                    type="file"
                    id="file"
                    ref={inputFileRef}
                    style={{ display: "none" }}
                    onChange={changedSelectedFiles}
                    multiple
                    accept="image/*,.pdf"
                  />
                  {formData.fisiereDovada.map((item, index) => (
                    <div key={index + item.name} style={styles.rowInput}>
                      <div
                        style={{
                          float: "left",
                          width: 190,
                          height: 24,
                          overflowY: "hidden",
                          overflowX: "ellipsis",
                          color: colors.main,
                          fontSize: 16,
                          lineHeight: "24px",
                        }}
                      >
                        {item.name}
                      </div>
                      <div
                        style={{
                          float: "left",
                          marginLeft: 6,
                          width: 14,
                          height: 14,
                          marginTop: 5,
                          cursor: "pointer",
                          fill: colors.main,
                        }}
                        onClick={() => {
                          removeFile(item.name, index)
                        }}
                      >
                        {svgs.closeIcon}
                      </div>
                    </div>
                  ))}
                  <div
                    style={{
                      // float: "left",
                      width: 24,
                      height: 24,
                      margin: "0 auto",
                      marginTop: 4,
                      cursor: "pointer",
                    }}
                    onClick={addFiles}
                  >
                    {svgs.addFile}
                  </div>
                  {/* <div
                style={{
                  float: "left",
                  width: 73,
                  marginTop: 4,
                  cursor: "pointer",
                }}
                onClick={addFiles}
              >
                {svgs.addFile}
              </div>
              <div
                style={{
                  float: "left",
                  marginLeft: 28,
                  cursor: "pointer",
                  width: 16,
                  marginTop: 4,
                }}
                onClick={addFiles}
              >
                {svgs.addFile}
              </div>
              <div
                style={{
                  float: "left",
                  marginLeft: 85,
                  cursor: "pointer",
                  width: 16,
                  marginTop: 4,
                }}
                onClick={addFiles}
              >
                {svgs.addFile}
              </div> */}
                </div>
              </div>

              <div style={{ marginTop: 64 }}>
                <button
                  disabled={!checkFormIsComplete(formData)}
                  className="buttonHover textButtonHover"
                  style={styles.loginButton}
                  onClick={sendDovada}
                >
                  Finalizare
                </button>
              </div>
            </>
          )}
          {infoMsg && (
            <>
              <Spinner
                containerStyle={{
                  width: 41,
                  height: 41,
                  margin: "100px auto 0px auto",
                }}
                show={infoMsg === "Vă rugăm așteptați"}
              />
              <div
                style={{
                  padding:
                    infoMsg === "Vă rugăm așteptați" ||
                    infoMsg.includes("Analizăm")
                      ? "200px 50px"
                      : "20px 50px",
                  // marginTop: 24,
                  textAlign:
                    infoMsg === "Vă rugăm așteptați" ? "center" : "justify",
                  fontSize: 18,
                  color:
                    infoMsg === "Vă rugăm așteptați"
                      ? colors.main
                      : infoMsg.includes("Analizăm")
                      ? colors.green
                      : colors.red,
                }}
              >
                {infoMsg}
              </div>
            </>
          )}
        </div>
      </div>
    )
  else return false
}
