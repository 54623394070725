import React from "react"

export default function useScrollRestorationToTop(
  querySelector: string,
  pathname: string
) {
  React.useEffect(() => {
    // console.log("useScrollRestorationToTop hook started: ", pathname)
    // window.scrollTo(0, 0)
    const root = document.querySelector(querySelector)
    // console.log("root: ", root)
    if (root != null)
      root.scrollTo({
        top: 0,
        left: 0,
        behavior: "auto",
      })
  }, [pathname])
}
