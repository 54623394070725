import React, { FormEvent } from "react"
import {
  postCheckVerificationCodeAndSwapEmail,
  postValidatePassword,
  postVerifyEmailForSwap,
} from "../../../utils/api/userInfo"
import {
  EMAIL_ERROR,
  EMAIL_REGEX,
  VALIDATION_CODE_ERROR,
  VALIDATION_CODE_REGEX,
} from "../../../utils/regex"
import { getUserInfo, saveUserInfo } from "../../../utils/services/keepAuth"
import Button from "../../Button"
import svgs from "../../Dash/svgs"
import FormInput from "../../FormInput"
import InfoMsg, { InfoMsgType } from "../../InfoMsg"

function checkFormData(formData: FormData) {
  switch (formData.step) {
    case 1:
      return formData.parola.length > 2
    case 2:
      return EMAIL_REGEX(formData.emailNou)
    case 3:
      return VALIDATION_CODE_REGEX(formData.emailVerificationCode)
    default:
      return false
  }
}

type PropTypes = {
  hideModal: () => void
  changeUserInfo: (value: string, type: string) => void
}

function getButtonLabelForStep(step: number) {
  switch (step) {
    case 1:
      return "Verifică parola"
    case 2:
      return "Trimite cod"
    case 3:
      return "Confirmă schimbarea"
    default:
      return ""
  }
}

function getHintMsgForStep(step: number) {
  switch (step) {
    case 1:
      return "Pentru a putea modifica adresa de email principală este necesar să introduci parola contului."
    case 2:
      return "Introduceți noua adresă de email"
    case 3:
      return "Introduceți codul primit pe email și finalizează schimbarea adresei de email"
    default:
      return ""
  }
}

type FormData = {
  step: number
  parola: string
  emailNou: string
  emailVerificationCode: string
}

export default function ModalChangeEmail({
  hideModal,
  changeUserInfo,
}: PropTypes) {
  const [infoMsg, setInfoMsg] = React.useState<InfoMsgType>({
    isLoading: false,
  })
  const [formData, setFormData] = React.useState<FormData>({
    step: 1,
    parola: "",
    emailNou: "",
    emailVerificationCode: "",
  })

  const handleInputChange = (value, name) => {
    // console.log("Changing form value: ", value, name)
    if (infoMsg.status === "error")
      setInfoMsg({
        isLoading: false,
      })
    setFormData(oldFormData => ({
      ...oldFormData,
      [name]: value,
    }))
  }

  const validatePassword = async () => {
    setInfoMsg({
      isLoading: true,
    })
    try {
      const apiResponse = await postValidatePassword({
        parola: formData.parola,
      })
      if (apiResponse.type) {
        setFormData(oldFormData => ({
          ...oldFormData,
          step: oldFormData.step + 1,
        }))
        setInfoMsg({
          isLoading: false,
        })
      } else {
        let msg = ""
        switch (apiResponse.reason) {
          case 0:
            msg =
              "Nu a fost găsit nici un cont pentru adresa de email introdusă"

            break
          case 1:
            msg = "Parola introdusă este greșită"
            break
          case 2:
            msg =
              "Contul dumneavoastră a fost blocat temporar din cauză ca ați introdus parola greșit de mai multe ori. Reîncercați în două ore sau resetațivă parola."

            break
          default:
            break
        }
        setInfoMsg({
          isLoading: false,
          status: "error",
          msg,
        })
      }
    } catch (error) {
      setInfoMsg({
        isLoading: false,
        status: "error",
        msg: "A apărut o eroare de comunicare. Vă rugăm reîncercați.",
      })
      console.log(error)
    }
  }

  const sendEmailCode = async () => {
    setInfoMsg({
      isLoading: true,
    })
    try {
      const apiResponse = await postVerifyEmailForSwap({
        emailNou: formData.emailNou,
        parola: formData.parola,
      })
      if (apiResponse.type) {
        setFormData(oldFormData => ({
          ...oldFormData,
          step: oldFormData.step + 1,
        }))
        setInfoMsg({
          isLoading: false,
        })
      } else
        setInfoMsg({
          isLoading: false,
          status: "error",
          msg: "Există deja un cont cu această adresa de email",
        })
    } catch (error) {
      setInfoMsg({
        isLoading: false,
        status: "error",
        msg: "A apărut o eroare de comunicare. Vă rugăm reîncercați.",
      })
      console.log(error)
    }
  }

  const validatePassCode = async () => {
    setInfoMsg({
      isLoading: true,
    })
    try {
      const apiResponse = await postCheckVerificationCodeAndSwapEmail({
        emailNou: formData.emailNou,
        emailVerificationCode: formData.emailVerificationCode,
      })
      if (apiResponse.type) {
        setInfoMsg({
          isLoading: false,
          status: "ok",
          msg: "Felicitări! Adresa de email a contului a fost schimbată. Acest pop-up va dispărea în cateva secunde",
        })
        changeUserInfo(formData.emailNou, "email")
        saveUserInfo({
          ...getUserInfo(),
          email: formData.emailNou,
        })
        setTimeout(hideModal, 2000)
        // setStep(3)
      } else
        setInfoMsg({
          isLoading: false,
          status: "error",
          msg: "Codul de validare este greșit.",
        })
    } catch (error) {
      setInfoMsg({
        isLoading: false,
        status: "error",
        msg: "A apărut o eroare de comunicare. Vă rugăm reîncercați.",
      })
      console.log(error)
    }
  }

  const submitForm = (e: FormEvent) => {
    e.preventDefault()
    // console.log("Step: ", step)
    switch (formData.step) {
      case 1:
        validatePassword()
        break
      case 2:
        sendEmailCode()
        break
      case 3:
        validatePassCode()
        break
      default:
        break
    }
    // setStep(oldStep => oldStep + 1)
    // tryUserLogin(formData)
  }

  // console.log(formData)

  return (
    <div className="popupLayout">
      <div className="popupContainer">
        <div
          className="popUpCloseIcon"
          role="button"
          tabIndex={0}
          // style={styles.loginLogoContainer}
          onClick={hideModal}
        >
          {svgs.closeIcon}
        </div>
        <div className="text">
          <div className="popupTitle popupExtraTitle colorPrimary">
            Schimbare email
          </div>
        </div>
        <div className="popupMsg textAlignLeft">
          {getHintMsgForStep(formData.step)}
        </div>
        <form
          id="changeEmailForm"
          className="newStyleForm"
          onSubmit={submitForm}
        >
          {formData.step === 1 && (
            <FormInput
              autoComplete="current-password"
              containerClassName="newStyleInput"
              type="password"
              label="Introdu parolă"
              name="parola"
              onChange={handleInputChange}
              value={formData.parola}
            />
          )}
          {formData.step > 1 && (
            <FormInput
              containerClassName="newStyleInput"
              type="email"
              label="Email nou"
              name="emailNou"
              onChange={handleInputChange}
              value={formData.emailNou}
              errorTest={EMAIL_REGEX}
              errorMessage={EMAIL_ERROR}
            />
          )}
          {formData.step === 3 && (
            <FormInput
              autoComplete="one-time-code"
              containerClassName="newStyleInput"
              type="text"
              label="Cod de verificare"
              name="emailVerificationCode"
              onChange={handleInputChange}
              value={formData.emailVerificationCode}
              errorTest={VALIDATION_CODE_REGEX}
              errorMessage={VALIDATION_CODE_ERROR}
            />
          )}

          <InfoMsg containerClassName="row" infoMsg={infoMsg} />
          <div className="row rowButton">
            <Button
              isLoading={infoMsg.isLoading}
              as="button"
              type="submit"
              label={getButtonLabelForStep(formData.step)}
              className="newStyleButton"
              aspect="primary"
              disabled={!checkFormData(formData)}
              // onClick={submitForm}
            />
          </div>
        </form>
      </div>
    </div>
  )
}
