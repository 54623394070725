import { navigate } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
// import { colors } from "../styles/globals"
import { LocalUserInfo, getUserInfo, logout } from "../utils/services/keepAuth"
import InfoClient from "./Dash/InfoClient"
import SideMenu from "./Dash/SideMenu"
import PopupLayout, { PopUpState } from "./Popups/PopupLayout"
import {
  PopupType,
  registerForDashPopUpCallbacks,
  removeDashPopUpCallbacks,
} from "./eventDispatchers/popupDispatcher"
import { get } from "http"

const testPopUpData: PopUpState = {
  showPopup: true,
  type: "logout",
  title: "Ai fost delogat cu succes",
  infoMsg: {
    isLoading: true,
    status: "ok",
    msg: "Te redirecționăm spre pagina de prezentare!",
  },
}
export default function DashPage({
  children,
  location,
  setShowPaymentRedirectLoading,
}) {
  const [userInfo, setUserInfo] = React.useState<LocalUserInfo | null>(null)
  const [popUpData, setPopUpData] = React.useState<PopUpState>({
    showPopup: false,
  })
  // const [popUpData, setPopUpData] = React.useState<PopUpState>(testPopUpData)

  React.useEffect(() => {
    // console.log(location.pathname)

    registerForDashPopUpCallbacks({
      onShowPopup,
    })

    if (
      !getUserInfo() &&
      !location.pathname.includes("/contact") &&
      !location.pathname.includes("/app/home")
    )
      navigate("/app/home")
    const localUserInfo = getUserInfo()
    if (localUserInfo != null) {
      setUserInfo(localUserInfo)
      // if (localUserInfo.denumire == null) navigate("/app/contFaraDate")
    }

    return removeDashPopUpCallbacks
  }, [])

  const onShowPopup = (type: PopupType) => {
    console.log("OnShowPopup", type)
    const root = document.getElementById("root")
    if (root)
      root.scrollTo({
        top: 0,
        left: 0,
        behavior: "auto",
      })
    switch (type) {
      case "logout":
        window.scrollTo(0, 0)
        setPopUpData({
          showPopup: true,
          type: type,
          title: "Ai fost delogat cu succes",
          infoMsg: {
            isLoading: true,
            status: "ok",
            msg: "Te redirecționăm spre pagina de prezentare!",
          },
        })
        logout()
        setTimeout(() => navigate("/"), 2000)
        break
      default:
        break
    }
  }

  // console.log(location)
  // console.log(location && location.pathname.includes("/home"))

  return [
    <div key={1} className="dashContainer">
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      {userInfo && !location.pathname.includes("verificareInfoClient") && (
        <SideMenu location={location} />
      )}

      {/* <AutoCitire /> */}
      {userInfo &&
      location &&
      !location.pathname.includes("/contact") &&
      !location.pathname.includes("verificareInfoClient") ? (
        <InfoClient
          containerClassName="hideOnMobile"
          setShowPaymentRedirectLoading={setShowPaymentRedirectLoading}
        />
      ) : // <NotificariAnunturi />,

      null}
      {children}
    </div>,
    popUpData.showPopup ? (
      <PopupLayout
        key={2}
        popupState={popUpData}
        querySelectorForBlurredBackground=".dashContainer"
      />
    ) : null,
  ]
}
