import React from "react"
import { Helmet } from "react-helmet"
import InfoClient from "../Dash/InfoClient"
import NotificariAnunturi from "../Dash/NotificariAnunturi"
import AutoCitire from "../Dash/AutoCitire"
import TemplatePage from "../TemplatePage"
import UsefullInfo from "../Dash/UsefullInfo"

const infoItem = [
  {
    title: "Prioritatea indexului?",
    link: "/infoUtile/faqs/generale?idSelectedFaq=6560b9e87d0b2f35b7b8473c",
  },
]

export default function AutoCitirePage({ location }) {
  const [showMoreInfo, setShowMoreInfo] = React.useState(true)

  const toggleShowMoreInfo = () => {
    setShowMoreInfo(oldValue => !oldValue)
  }

  return (
    <TemplatePage
      showDash
      location={location}
      title="Autocitire"
      showMobileMenu={true}
      additionalTitleClassName="bgGrayOnTablet"
      additionalClassName="autoCitirePage"
    >
      <Helmet>
        <title>Autocitire - Gazmir.ro</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <AutoCitire />
      <UsefullInfo
        title="Informații despre autocitire:"
        items={infoItem}
        expanded={true}
        toggleShowMoreInfo={toggleShowMoreInfo}
      />
      {/* <div className="faqs"></div> */}
    </TemplatePage>
  )
}
