export type PopupType = "logout" | "activateClientAccount" | "infoCerere"

type DashPopUpCallbacks = {
  onShowPopup: (type: PopupType) => void
}

let dashPopUpCallbacks: DashPopUpCallbacks | null = null

export function registerForDashPopUpCallbacks(callbacks: DashPopUpCallbacks) {
  console.log("registerForDashPopUpCallbacks", callbacks)
  dashPopUpCallbacks = callbacks
}

export function removeDashPopUpCallbacks() {
  dashPopUpCallbacks = null
}

export function emitShowPopup(type: PopupType) {
  console.log("emitShowPopup", type, dashPopUpCallbacks)
  if (dashPopUpCallbacks) {
    dashPopUpCallbacks.onShowPopup(type)
  }
}
