import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { colors } from "../../../styles/globals"
import { putUserNotificationsInfo } from "../../../utils/api/userInfo"
import { getUserInfo, saveUserInfo } from "../../../utils/services/keepAuth"
import Button from "../../Button"
import svgs from "../../Dash/svgs"
import InfoMsg, { InfoMsgType } from "../../InfoMsg"
import Spinner from "../../Spinner"

export default function ModalDisableFacturaElectronica({
  hideModal,
  changeUserInfo,
}) {
  const [infoMsg, setInfoMsg] = React.useState<InfoMsgType>({
    isLoading: false,
  })

  const tryDisableFacturaElectronica = async () => {
    setInfoMsg({
      isLoading: true,
    })
    try {
      const apiResponse = await putUserNotificationsInfo({
        facturaElectronica: false,
      })
      if (apiResponse.type) {
        setInfoMsg({
          isLoading: true,
          status: "ok",
          msg: "Modificarea a fost salvată cu succes dureaza 2-3 zile să se execute schimbarea",
        })
        saveUserInfo({ ...getUserInfo(), facturaElectronica: false })
        changeUserInfo(false, "facturaElectronica")
        setTimeout(hideModal, 2000)
      } else
        setInfoMsg({
          isLoading: false,
          status: "error",
          msg: "A apărut o eroare de comunicare. Vă rugăm reîncercați.",
        })
    } catch (error) {
      setInfoMsg({
        isLoading: false,
        status: "error",
        msg: "A apărut o eroare de comunicare. Vă rugăm reîncercați.",
      })
      console.log(error)
    }
  }

  // console.log(formData)
  return (
    <div className="popupLayout">
      <div className="popupContainer">
        <div
          className="popUpCloseIcon"
          role="button"
          tabIndex={0}
          // style={styles.loginLogoContainer}
          onClick={hideModal}
        >
          {svgs.closeIcon}
        </div>
        <div className="text">
          <div className="popupTitle popupExtraTitle  colorPrimary">
            Dezactivare facturare electronică
          </div>
        </div>
        <div className="popupMsg textAlignLeft">
          Sunteti sigur ca doriti sa dezactivati facturarea electronica?"
        </div>

        <div className="newStyleForm">
          <InfoMsg containerClassName="row" infoMsg={infoMsg} />
          <div className="row rowButton">
            <Button
              isLoading={infoMsg.isLoading}
              as="button"
              type="button"
              label="Da, sunt sigur"
              className="newStyleButton"
              aspect="danger"
              onClick={tryDisableFacturaElectronica}
            />
          </div>

          <div className="row rowButton">
            <Button
              as="button"
              type="button"
              label="Nu, renuntă "
              className="newStyleButton"
              aspect="noBackground"
              onClick={hideModal}
            />
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div
      className="modalContainer modalSetari"
      // onClick={toggleLoginModal}
    >
      <div
        className="loginContainer"
        // onClick={undefined}
      >
        <div
          role="button"
          tabIndex={0}
          style={styles.loginLogoContainer}
          onClick={hideModal}
        >
          {/* <img alt="close icon" src="/closeIcon.svg" style={{ width: 15 }} /> */}
          {svgs.closeIcon}
        </div>
        <div className="modalLogoContainer hideOn600">
          <StaticImage
            style={{ marginTop: 32 }}
            height={120}
            loading="eager"
            layout="constrained"
            src="../images/logoNou.png"
            alt="Logo gazmir"
          />
        </div>
        <div className="formTitleMobile fontBold showOn600 colorPrimary">
          Dezactivare facturare electronică
        </div>

        {/* <div></div> */}
        <div className="modalForm">
          {/* <div style={{ fontSize: 22, fontWeight: "bold", color: colors.main }}> */}

          {/* </div> */}
          {infoMsg && (
            <div
              style={{
                display: "inline-block",
                width: "100%",
                padding: "20px 0px",
                // marginTop: 32,
                textAlign:
                  infoMsg === "Vă rugăm așteptați" ? "center" : "justify",
                fontSize: 22,
                color: infoMsg.includes("succes") ? colors.green : colors.main,
                fontWeight: 700,
              }}
            >
              {infoMsg}
            </div>
          )}
        </div>

        <div
          className="modalButtonsContainer"
          style={{ justifyContent: "space-between" }}
        >
          <Spinner
            containerStyle={{
              width: 41,
              height: 41,
              margin: "40px auto 0px auto",
            }}
            show={infoMsg === "Vă rugăm așteptați"}
          />
          {infoMsg !== "Vă rugăm așteptați" &&
            infoMsg !==
              "Modificarea a fost salvată cu succes dureaza 2-3 zile să se execute schimbarea" && [
              <button
                className="buttonHover textButtonHover loginButton"
                style={{ maxWidth: 130 }}
                onClick={hideModal}
              >
                Nu, renuntă
              </button>,
              <button
                className="buttonHover textButtonHover loginButton"
                style={{ maxWidth: 130 }}
                onClick={tryDisableFacturaElectronica}
              >
                Da, sunt sigur
              </button>,
            ]}
        </div>
      </div>
    </div>
  )
}
