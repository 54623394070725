import React from "react"
import useFixedTooltip from "../../Hooks/useFixedTooltip"
import svgs from "../../Dash/svgs"

type PropTypes = {
  hasPdf: boolean
  isFacturaCurenta: boolean
  onClick: () => void
}

export default function TabelActionCell({
  hasPdf,
  isFacturaCurenta,
  onClick,
}: PropTypes) {
  const tooltipSpanRef = React.useRef<HTMLSpanElement | null>(null)
  const tooltipParrentOnMouseEnter = useFixedTooltip(tooltipSpanRef, {
    x: -190,
    y: 30,
  })

  return (
    <td
      style={{
        cursor: hasPdf ? "pointer" : "initial",
      }}
      onClick={onClick}
    >
      {hasPdf ? (
        <svg
          style={{ height: 30 }}
          viewBox="0 0 24 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.0002 14.4L16.8002 8.4H13.2002V0H10.8002V8.4H7.2002L12.0002 14.4Z"
            fill="#002F49"
          />
          <path
            d="M21.6 16.8H2.4V8.40002H0V16.8C0 18.1236 1.0764 19.2 2.4 19.2H21.6C22.9236 19.2 24 18.1236 24 16.8V8.40002H21.6V16.8Z"
            fill="#002F49"
          />
        </svg>
      ) : isFacturaCurenta ? (
        <div
          className="tooltip infoIcon facturiInfoIcon"
          onMouseEnter={tooltipParrentOnMouseEnter}
        >
          {svgs.info}
          <span ref={tooltipSpanRef} className="tooltiptext">
            Factura a fost calculată, dar încă nu a fost creată în format PDF.
            Lucrăm chiar acum la trimiterea facturii pe emailul dvs., cât și
            pentru a o adăuga aici.
          </span>
        </div>
      ) : null}
    </td>
  )
}
