import React, { FormEvent } from "react"
import { postResetPassword } from "../../../utils/api/userInfo"
import {
  CONFIRM_PASSWORD_ERROR,
  PASSWORD_ERROR,
  PASSWORD_REGEX,
} from "../../../utils/regex"
import Button from "../../Button"
import svgs from "../../Dash/svgs"
import FormInput from "../../FormInput"
import InfoMsg, { InfoMsgType } from "../../InfoMsg"

function checkFormData(formData) {
  return (
    formData.parolaVeche.length > 0 &&
    PASSWORD_REGEX(formData.parolaNoua) &&
    formData.parolaNoua === formData.parolaNouaConfirm
  )
}

type PropType = {
  hideModal: () => void
}

type FormData = {
  parolaVeche: string
  parolaNoua: string
  parolaNouaConfirm: string
}

export default function PopUpChangePassword({ hideModal }: PropType) {
  const [infoMsg, setInfoMsg] = React.useState<InfoMsgType>({
    isLoading: false,
  })
  const [formData, setFormData] = React.useState<FormData>({
    parolaVeche: "",
    parolaNoua: "",
    parolaNouaConfirm: "",
  })

  const testConfirmPassword = (parolaConfirm: string) =>
    formData.parolaNoua === parolaConfirm

  const handleInputChange = (value: string, name: string) => {
    setFormData(oldFormData => ({
      ...oldFormData,
      [name]: value,
    }))
  }

  const tryResetPassword = async (e: FormEvent) => {
    e.preventDefault()
    setInfoMsg({
      isLoading: true,
    })
    try {
      const apiResponse = await postResetPassword({
        parolaVeche: formData.parolaVeche,
        parolaNoua: formData.parolaNoua,
      })
      if (apiResponse.type) {
        setInfoMsg({
          isLoading: false,
          status: "ok",
          msg: "Felicitări! Parola contului a fost schimbată. Acest pop-up va dispărea în cateva secunde",
        })

        setTimeout(hideModal, 2000)
      } else
        setInfoMsg({
          isLoading: false,
          status: "error",
          msg: "Parola curentă introdusă nu este corectă.",
        })
    } catch (error) {
      setInfoMsg({
        isLoading: false,
        status: "error",
        msg: "A apărut o eroare de comunicare. Vă rugăm reîncercați.",
      })
      console.log(error)
    }
  }

  // console.log(formData)

  return (
    <div className="popupLayout">
      <div className="popupContainer">
        <div
          className="popUpCloseIcon"
          role="button"
          tabIndex={0}
          // style={styles.loginLogoContainer}
          onClick={hideModal}
        >
          {svgs.closeIcon}
        </div>
        <div className="text">
          <div className="popupTitle popupExtraTitle colorPrimary">
            Resetare parolă
          </div>
        </div>
        <div className="popupMsg textAlignLeft">
          Introdu parolă actuală și noua parolă pentru a schimba parola contului
          tău.
        </div>
        <form
          id="changePasswordForm"
          className="newStyleForm"
          onSubmit={tryResetPassword}
        >
          <FormInput
            autoComplete="current-password"
            containerClassName="newStyleInput"
            type="password"
            label="Introdu parolă curentă"
            name="parolaVeche"
            onChange={handleInputChange}
            value={formData.parolaVeche}
            // errorTest={PASSWORD_REGEX}
            // errorMessage={PASSWORD_ERROR}
          />
          <FormInput
            autoComplete="new-password"
            containerClassName="newStyleInput"
            type="password"
            label="Introdu noua parolă"
            name="parolaNoua"
            onChange={handleInputChange}
            value={formData.parolaNoua}
            errorTest={PASSWORD_REGEX}
            errorMessage={PASSWORD_ERROR}
          />

          <FormInput
            autoComplete="new-password"
            containerClassName="newStyleInput"
            type="password"
            label="Confirmă noua parolă"
            name="parolaNouaConfirm"
            onChange={handleInputChange}
            value={formData.parolaNouaConfirm}
            errorTest={testConfirmPassword}
            errorMessage={CONFIRM_PASSWORD_ERROR}
          />

          <InfoMsg containerClassName="row" infoMsg={infoMsg} />
          <div className="row rowButton">
            <Button
              isLoading={infoMsg.isLoading}
              as="button"
              type="submit"
              label="Schimbă parola"
              className="newStyleButton"
              aspect="primary"
              disabled={!checkFormData(formData)}
              // onClick={submitForm}
            />
          </div>
        </form>
      </div>
    </div>
  )
}
