import { RouteComponentProps } from "@reach/router"
import React from "react"
import {
  putUserInfo,
  putUserNotificationsInfo,
} from "../../../utils/api/userInfo"
import {
  ALFA_NUMERIC_ERROR,
  ALFA_NUMERIC_REGEX,
  EMAIL_ERROR,
  EMAIL_REGEX,
  PHONENUMBER_ERROR,
  PHONENUMBER_REGEX,
} from "../../../utils/regex"
import {
  LocalContract,
  getContracts,
  getUserInfo,
  saveContracts,
  saveUserInfo,
} from "../../../utils/services/keepAuth"
import Button from "../../Button"
import userBlurredBackground from "../../Hooks/useBlurredBackground"
import { useStopBodyScrollingWithCondition } from "../../Hooks/useStopBodyScrollingWithCondition"
import InfoMsg, { InfoMsgType } from "../../InfoMsg"
import TemplatePage from "../../TemplatePage"
import { emitShowPopup } from "../../eventDispatchers/popupDispatcher"
import ModalChangeEmail from "./ModalChangeEmail"
import ModalChangePassword from "./ModalChangePassword"
import ModalDeleteAccount from "./ModalDeleteAccount"
import ModalDisableFacturaElectronica from "./ModalDisableFacturaElectronica"
import SettingsInputRow from "./SettingsInputRow"
import "./style.css"

function checkFieldValueChanged(formData, name) {
  const localUserInfo = getUserInfo()
  if (formData[name] === "") return false
  if (localUserInfo != null)
    return formData[name] !== (localUserInfo[name] || "")
  else return false
}

function checkAdresaLocConsumChanged(adresaClc) {
  const localContracts = getContracts()
  if (localContracts != null && localContracts.length > 0)
    return adresaClc !== localContracts[0].adresaClc
  else return adresaClc !== "Indisponibil"
}

function canSaveData(formData) {
  console.log(formData)
  let verificareTelefon = true
  let verificareTelefonUrgente = true
  let verificareEmailSecundar = true
  let verificareDenumire = true

  let verificareAdresaClc = true

  if (checkFieldValueChanged(formData, "denumire")) {
    //Inseamnă ca e modificat. Ca să pot salva trebuie să văd și dacă e corect completat.
    verificareDenumire = ALFA_NUMERIC_REGEX(formData.denumire)
  } else if (formData.denumire === "") verificareDenumire = false

  if (checkFieldValueChanged(formData, "telefon")) {
    //Inseamnă ca e modificat. Ca să pot salva trebuie să văd și dacă e corect completat.
    verificareTelefon = PHONENUMBER_REGEX(formData.telefon)
  }
  if (checkFieldValueChanged(formData, "telUrgente")) {
    //Inseamnă ca e modificat. Ca să pot salva trebuie să văd și dacă e corect completat.
    verificareTelefonUrgente = PHONENUMBER_REGEX(formData.telUrgente)
  }
  if (checkFieldValueChanged(formData, "emailSecundar")) {
    //Inseamnă ca e modificat. Ca să pot salva trebuie să văd și dacă e corect completat.
    verificareEmailSecundar = EMAIL_REGEX(formData.emailSecundar)
  }
  if (checkAdresaLocConsumChanged(formData.adresaClc))
    verificareAdresaClc = ALFA_NUMERIC_REGEX(formData.adresaClc)

  if (
    !checkFieldValueChanged(formData, "denumire") &&
    !checkFieldValueChanged(formData, "telefon") &&
    !checkFieldValueChanged(formData, "telUrgente") &&
    !checkFieldValueChanged(formData, "emailSecundar") &&
    !checkAdresaLocConsumChanged(formData.adresaClc)
  )
    return false
  else
    return (
      verificareDenumire &&
      verificareTelefon &&
      verificareAdresaClc &&
      verificareTelefonUrgente &&
      verificareEmailSecundar
    )
}

function showErrorForFormData(formData: UserInfo & { adresaClc: string }) {
  if (
    checkFieldValueChanged(formData, "denumire") &&
    !ALFA_NUMERIC_REGEX(formData.denumire)
  )
    return ALFA_NUMERIC_ERROR
  if (
    checkFieldValueChanged(formData, "telefon") &&
    !PHONENUMBER_REGEX(formData.telefon)
  )
    return "Numărul de telefon trebuie să conțină 10 cifre"
  if (
    checkFieldValueChanged(formData, "telUrgente") &&
    !PHONENUMBER_REGEX(formData.telUrgente)
  )
    return "Numărul de telefon contact de urgențe trebuie să conțină 10 cifre"
  if (
    checkFieldValueChanged(formData, "emailSecundar") &&
    !EMAIL_REGEX(formData.emailSecundar)
  )
    return EMAIL_ERROR
  if (
    checkAdresaLocConsumChanged(formData.adresaClc) &&
    !ALFA_NUMERIC_REGEX(formData.adresaClc)
  )
    return ALFA_NUMERIC_ERROR
  return ""
}

type UserInfo = {
  denumire: string
  telefon: string
  telUrgente: string
  emailSecundar: string
  parola: string
  email: string
  facturaElectronica: boolean
  notificareSmsFactura: boolean
}

export default function SetariAdministrativePage({
  location,
  navigate,
}: RouteComponentProps) {
  const [userInfo, setUserInfo] = React.useState<UserInfo | null>(null)
  const [contract, setContract] = React.useState<LocalContract | null>(null)
  // const [canSaveInfo, setCanSaveInfo] = React.useState(false)
  const [showModalChangePassword, setShowModalChangePassword] =
    React.useState(false)
  const [showModalChangeEmailPrincipal, setShowModalChangeEmailPrincipal] =
    React.useState(false)

  const [
    showModalDisableFacturaElectronica,
    setShowModalDisableFacturaElectronica,
  ] = React.useState(false)

  const [showModalDeleteAccount, setShowModalDeleteAccount] =
    React.useState(false)

  const [infoMsg, setInfoMsg] = React.useState<InfoMsgType>({
    isLoading: false,
  })

  userBlurredBackground({
    querySelector: ".saContentContainer ",
    showPopup:
      showModalChangeEmailPrincipal ||
      showModalChangePassword ||
      showModalDisableFacturaElectronica ||
      showModalDeleteAccount,
  })

  useStopBodyScrollingWithCondition({
    shouldStopScrolling:
      showModalChangeEmailPrincipal ||
      showModalChangePassword ||
      showModalDisableFacturaElectronica ||
      showModalDeleteAccount,
  })

  React.useEffect(() => {
    const localUserInfo = getUserInfo()
    const localContracts = getContracts()
    if (localUserInfo != null)
      setUserInfo({
        denumire: localUserInfo.denumire || "",
        telUrgente: localUserInfo.telUrgente || "",
        emailSecundar: localUserInfo.emailSecundar || "",
        parola: "*******",
        email: localUserInfo.email || "",
        telefon: localUserInfo.telefon || "",
        facturaElectronica: localUserInfo.facturaElectronica,
        notificareSmsFactura: localUserInfo.notificareSmsFactura,
      })
    if (localContracts != null) setContract(localContracts[0])
    else if (navigate != null) navigate("/")
  }, [])

  const changeUserInfo = (value: string, name: string) => {
    if (infoMsg.status === "error") setInfoMsg({ isLoading: false })
    setUserInfo(oldData =>
      oldData != null
        ? {
            ...oldData,
            [name]: value,
          }
        : null
    )
  }

  const changeAdresaLocConsum = value => {
    if (infoMsg.status === "error") setInfoMsg({ isLoading: false })
    setContract(oldValue =>
      oldValue != null
        ? {
            ...oldValue,
            adresaClc: value,
          }
        : null
    )
  }

  const updateUserInfo = async () => {
    setInfoMsg({ isLoading: true })
    if (userInfo != null && contract != null)
      try {
        const apiResponse = await putUserInfo({
          denumire: userInfo.denumire,
          telefon: userInfo.telefon,
          telUrgente: userInfo.telUrgente,
          emailSecundar: userInfo.emailSecundar,
          adresaClc: contract.adresaClc,
        })
        if (apiResponse.type) {
          setInfoMsg({
            isLoading: false,
            status: "ok",
            msg: "Modificarea a fost salvată cu succes!",
          })
          saveUserInfo({
            ...getUserInfo(),
            denumire: userInfo.denumire,
            telefon: userInfo.telefon,
            telUrgente: userInfo.telUrgente,
            emailSecundar: userInfo.emailSecundar,
          })

          saveContracts([
            { ...getContracts[0], adresaClc: contract.adresaClc },
            getContracts()!.splice(1),
          ])
          const localUserInfo = getUserInfo()
          if (localUserInfo != null)
            setUserInfo({
              denumire: localUserInfo.denumire || "",
              telUrgente: localUserInfo.telUrgente || "",
              emailSecundar: localUserInfo.emailSecundar || "",
              parola: "*******",
              email: localUserInfo.email || "",
              telefon: localUserInfo.telefon || "",
              facturaElectronica: localUserInfo.facturaElectronica,
              notificareSmsFactura: localUserInfo.notificareSmsFactura,
            })
        } else
          setInfoMsg({
            isLoading: false,
            status: "error",
            msg: "A apărut o eroare de comunicare. Vă rugăm reîncercați.",
          })
      } catch (error) {
        console.error(error)
        setInfoMsg({
          isLoading: false,
          status: "error",
          msg: "A apărut o eroare de comunicare. Vă rugăm reîncercați.",
        })
      }
  }

  const toggleModalChangePassword = () => {
    if (!showModalChangePassword) {
      const root = document.getElementById("root")
      if (root != null)
        root.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        })
    }
    setShowModalChangePassword(oldValue => !oldValue)
  }

  const toggleModalChangeEmailPrincipal = () => {
    if (!showModalChangeEmailPrincipal) {
      const root = document.getElementById("root")
      if (root != null)
        root.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        })
    }
    setShowModalChangeEmailPrincipal(oldValue => !oldValue)
  }

  const toggleModalDisableFacturaElectronica = () => {
    if (!showModalDisableFacturaElectronica) {
      const root = document.getElementById("root")
      if (root != null)
        root.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        })
    }
    setShowModalDisableFacturaElectronica(oldValue => !oldValue)
  }

  const toggleModalDeleteAccount = () => {
    if (!showModalDeleteAccount) {
      const root = document.getElementById("root")
      if (root != null)
        root.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        })
    }
    setShowModalDeleteAccount(oldValue => !oldValue)
  }

  const changeFacturaElectronica = async () => {
    // console.log("Changing factura electronica")
    if (userInfo != null && !userInfo.facturaElectronica)
      try {
        const apiResponse = await putUserNotificationsInfo({
          facturaElectronica: true,
        })
        if (apiResponse.type) {
          setUserInfo(oldData =>
            oldData != null
              ? {
                  ...oldData,
                  facturaElectronica: true,
                }
              : null
          )
          saveUserInfo({ ...getUserInfo(), facturaElectronica: true })
        }
      } catch (error) {
        console.error(error)
      }
    else toggleModalDisableFacturaElectronica()
  }

  const changeNotificareSmsFactura = async () => {
    if (userInfo != null)
      try {
        const apiResponse = await putUserNotificationsInfo({
          notificareSmsFactura: !userInfo.notificareSmsFactura,
        })
        if (apiResponse.type) {
          saveUserInfo({
            ...getUserInfo(),
            notificareSmsFactura: !userInfo.notificareSmsFactura,
          })
          setUserInfo(prevData =>
            prevData != null
              ? {
                  ...prevData,
                  notificareSmsFactura: !prevData.notificareSmsFactura,
                }
              : null
          )
        }
      } catch (error) {
        console.error(error)
      }
  }

  const onLogoutPressed = () => {
    console.log("Logout pressed")
    emitShowPopup("logout")
  }

  // console.log(userInfo)
  //FIXME: Double scrollable page on mobile
  return (
    <>
      <TemplatePage
        // additionalClassName="contactPage"
        showDash
        location={location!}
        title="Setări cont"
        showMobileMenu={false}
        additionalClassName={
          showModalChangeEmailPrincipal ||
          showModalChangePassword ||
          showModalDisableFacturaElectronica ||
          showModalDeleteAccount
            ? "stopScrolling"
            : ""
        }
        additionalTitleClassName="bgGrayOnTablet"
      >
        {userInfo != null && userInfo.denumire != null && (
          <div className="saContentContainer">
            <SettingsInputRow
              label="Nume și prenume:"
              placeholder="Nume și prenume"
              name="denumire"
              onChange={changeUserInfo}
              value={userInfo.denumire.toLowerCase()}
              errorTest={
                checkFieldValueChanged(userInfo, "denumire")
                  ? ALFA_NUMERIC_REGEX
                  : undefined
              }
              errorMessage={ALFA_NUMERIC_ERROR}
              disabled={false}
            />
            <SettingsInputRow
              name="clc"
              label="Cod loc consum: "
              placeholder="Cod loc consum"
              value={contract?.clc ?? "Indisponibil"}
              onChange={changeUserInfo}
              // onClick={() => {}}
              disabled={true}
            />
            <SettingsInputRow
              type="textarea"
              label="Adresă loc de consum:"
              placeholder="Adresă loc de consum"
              name="adresaClc"
              value={contract?.adresaClc.toLowerCase() ?? "Indisponibil"}
              onChange={changeAdresaLocConsum}
              errorTest={
                checkFieldValueChanged(contract, "adresaClc")
                  ? ALFA_NUMERIC_REGEX
                  : undefined
              }
              errorMessage={ALFA_NUMERIC_ERROR}
              disabled={false}
            />
            <SettingsInputRow
              label="Telefon:"
              placeholder="Telefon"
              name="telefon"
              onChange={changeUserInfo}
              value={userInfo.telefon}
              errorTest={
                checkFieldValueChanged(userInfo, "telefon")
                  ? PHONENUMBER_REGEX
                  : undefined
              }
              errorMessage={PHONENUMBER_ERROR}
              disabled={false}
            />
            <SettingsInputRow
              label="Telefon urgențe:"
              placeholder="Telefon urgențe"
              name="telUrgente"
              onChange={changeUserInfo}
              value={userInfo.telUrgente}
              errorTest={
                checkFieldValueChanged(userInfo, "telUrgente")
                  ? PHONENUMBER_REGEX
                  : undefined
              }
              errorMessage={PHONENUMBER_ERROR}
              disabled={false}
            />
            <SettingsInputRow
              type="password"
              name="parola"
              label="Parolă:"
              placeholder="Parolă"
              value={userInfo.parola}
              onClick={toggleModalChangePassword}
            />
            <SettingsInputRow
              name="emailPrinciapl"
              label="Email principal:"
              placeholder="Email principal"
              value={userInfo.email}
              onClick={toggleModalChangeEmailPrincipal}
            />
            <SettingsInputRow
              label="Email secundar:"
              placeholder="Email secundar"
              disabled={false}
              onChange={changeUserInfo}
              name="emailSecundar"
              value={userInfo.emailSecundar}
              errorTest={
                checkFieldValueChanged(userInfo, "emailSecundar")
                  ? EMAIL_REGEX
                  : undefined
              }
              errorMessage={EMAIL_ERROR}
            />
            <SettingsInputRow
              type="checkbox"
              name="facturaElectronica"
              label="Factură electronică:"
              placeholder="Factură electronică"
              // placeholder="Parolă"
              disabled={false}
              value={userInfo.facturaElectronica + ""}
              onChange={changeFacturaElectronica}
              tooltipText="Vă rugăm să vă asigurați ca aveti acces la adresă de email."
            />
            {/* <SettingsInputRow
              type="checkbox"
              label="Notificare emitere factură prin SMS:"
              // placeholder="Parolă"
              disabled={true}
              value={userInfo.notificareSmsFactura}
              // onChange={changeNotificareSmsFactura}
              // tooltipText="Vă informăm că notificarea de emitere a facturii, trimisă prin SMS, se va transmite către numărul principal de telefon din câmpul Telefon al contului dvs. Vă rugăm să vă asigurați că aveți acces la aceast număr de telefon și că acesta este funcțional."
              tooltipText="Momentan această funcțiune este indisponibilă. Verificați mai tărziu disponibilitatea ei."
            /> */}
            <SettingsInputRow
              type="checkbox"
              label="Șterge cont"
              name="deleteAccount"
              placeholder="Șterge cont"
              disabled={false}
              value={false + ""}
              onChange={toggleModalDeleteAccount}
              tooltipText="Ștergerea contului dvs. implică ștergerea datelor aferente contului dvs online. Acest lucru nu presupune ștergerea datelor în format fizic ce au fost atașate la dosarul dvs de client, aflat în arhiva GAZMIR, ca urmare a solicitărilor transmise de dvs prin intermediul contului online Gazmir."
            />
            <div className="saRow saRowLogout">
              <InfoMsg
                infoMsg={{
                  isLoading: infoMsg.isLoading,
                  status:
                    infoMsg.status != null
                      ? infoMsg.status
                      : showErrorForFormData({
                          ...userInfo,
                          adresaClc: contract?.adresaClc ?? "",
                        }).length > 0
                      ? "error"
                      : undefined,
                  msg:
                    infoMsg.msg != null
                      ? infoMsg.msg
                      : showErrorForFormData({
                          ...userInfo,
                          adresaClc: contract?.adresaClc ?? "",
                        }),
                }}
              />
            </div>
            <div className="saRow saRowLogout">
              <Button
                isLoading={infoMsg.isLoading}
                as="button"
                type="button"
                aspect="primary"
                className="newStyleButton"
                label="Salvează modificările"
                onClick={updateUserInfo}
                disabled={!canSaveData(userInfo)}
              />
            </div>
            {/* <div style={{ ...styles.row }}>
              {infoMsg !== "Vă rugăm așteptați" && (
                <button
                  type="button"
                  className="subMenuHover"
                  style={styles.submitButton}
                  onClick={updateUserInfo}
                  disabled={
                    !canSaveData({
                      ...userInfo,
                      adresaClc: contract?.adresaClc ?? "",
                    })
                  }
                >
                  Salvează modificările
                </button>
              )}
            </div> */}

            <div className="saRow saRowLogout">
              <Button
                as="button"
                type="button"
                aspect="danger"
                className="newStyleButton logOutButton"
                label="Delogare"
                onClick={onLogoutPressed}
              />
            </div>
          </div>
        )}
        {showModalChangeEmailPrincipal && (
          <ModalChangeEmail
            hideModal={toggleModalChangeEmailPrincipal}
            changeUserInfo={changeUserInfo}
          />
        )}
        {showModalChangePassword && (
          <ModalChangePassword hideModal={toggleModalChangePassword} />
        )}
        {showModalDisableFacturaElectronica && (
          <ModalDisableFacturaElectronica
            hideModal={toggleModalDisableFacturaElectronica}
            changeUserInfo={changeUserInfo}
          />
        )}
        {showModalDeleteAccount && (
          <ModalDeleteAccount hideModal={toggleModalDeleteAccount} />
        )}
      </TemplatePage>
    </>
  )
}
