import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import svgs from "./Dash/svgs"

type PropTypes = {
  isAbsolutePositioning?: boolean
  containerClassName?: string
}

const footerLinks = [
  {
    title: "Termeni",
    href: "/documente/Terms&Conditions.pdf",
  },
  {
    title: "Confidențialitate",
    href: "/documente/PrivacyPolicy.pdf",
  },
  {
    title: "Cookies",
    href: "/documente/CookiePolicy.pdf",
  },
  {
    title: "Transgaz",
    href: "https://www.transgaz.ro/ro",
  },
  {
    title: "A.N.R.E.",
    href: "https://www.anre.ro/",
  },
  {
    title: "A.N.P.C.",
    href: "https://anpc.ro/",
  },
  { title: "InfoCons", href: "https://infocons.ro/" },
  {
    title: "POSF",
    href: "https://posf.ro",
  },
  {
    title: "Telverde",
    href: "tel:0800.800.528",
  },
  {
    title: "Plângeri și sesizări",
    href: "/plangeriSiSesizari",
  },
]

const footerLinksMobile = [
  [
    {
      title: "A.N.R.E.",
      href: "https://www.anre.ro/",
    },
    {
      title: "POSF",
      href: "https://posf.ro",
    },
    { title: "InfoCons", href: "https://infocons.ro/" },
    {
      title: "A.N.P.C.",
      href: "https://anpc.ro/",
    },
    {
      title: "Transgaz",
      href: "https://www.transgaz.ro/ro",
    },
    {
      title: "Telverde",
      href: "tel:0800.800.528",
    },
  ],
  [
    {
      title: "Termeni",
      href: "/documente/Terms&Conditions.pdf",
    },
    {
      title: "Confidențialitate",
      href: "/documente/PrivacyPolicy.pdf",
    },
    {
      title: "Cookies",
      href: "/documente/CookiePolicy.pdf",
    },
    {
      title: "0232.24.24.66",
      href: "tel:0232242466",
    },
    {
      title: "office@gazmir.ro",
      href: "mailto:office@gazmir.ro",
    },
  ],
]

export default function Footer({
  isAbsolutePositioning = false,
  containerClassName = "",
}: PropTypes) {
  return (
    <div
      className={
        containerClassName +
        " footerContainer" +
        (isAbsolutePositioning ? " footerContainerAbsolute" : "")
      }
    >
      <div className="footerLeft hideOnMobile">
        <ul className="footerLinksContainer hideOnMobile">
          {footerLinks.map(item => (
            <li key={item.title} className="footerLinkListItem">
              <a
                className="footerLink footerLinkHover"
                target="_blank"
                href={item.href}
              >
                <span className="footerLinkText">{item.title}</span>
              </a>
            </li>
          ))}
          <li className="footerLinkListItem footerCopyRightText">
            {`© Gazmir Iași ${new Date().getFullYear()}`}
          </li>
        </ul>
        {/* <ul className="footerLinksContainer hideOnMobile">
          {footerLinks.splice(5).map(item => (
            <li key={item.title} className="footerLinkListItem">
              <a
                className="footerLink footerLinkHover"
                target="_blank"
                href={item.href}
              >
                <span className="footerLinkText">{item.title}</span>
              </a>
            </li>
          ))}
        </ul> */}
      </div>
      <div className="footerCopyRightContainer hideOnMobile">
        <div className="footerCopyLogos">
          <div className="footerVisaLogo">{svgs.visa}</div>
          <div className="footerMasterCardLogo">{svgs.mastercard}</div>
        </div>
        <div className="footerCopyLogos">
          <div key="anpc-sal" className="">
            <a className="" target="_blank" href="https://anpc.ro/ce-este-sal/">
              <StaticImage
                height={50}
                className="anpcSal"
                src="../images/anpc-sal.webp"
                alt="A.N.P.C. - CE ESTE SAL?"
              />
            </a>
          </div>
          <div key="eu-sol" className="">
            <a
              className=""
              target="_blank"
              href="http://ec.europa.eu/consumers/odr"
            >
              <StaticImage
                height={50}
                className="anpcSal"
                src="../images/anpc-sol.webp"
                alt="A.N.P.C. - S.O.L."
              />
            </a>
          </div>
        </div>
      </div>

      <div className="footerLinksContainerMobile visibleOnTabletWithFlex">
        <div className="footerLinkListContainer">
          <span className="footerLinkListTitle">Linkuri utile:</span>
          <ul className="footerLinksContainer">
            {footerLinksMobile[0].map(link => (
              <li key={link.title} className="footerLinkListItem">
                <a
                  className="footerLink footerLinkHover"
                  target="_blank"
                  href={link.href}
                >
                  <span className="footerLinkText">{link.title}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="footerHorizontalBar"></div>
        <div className="footerLinkListContainer">
          {/* <span className="footerLinkListTitle" style={{ color: "#002f49" }}>
            Gazmir
          </span> */}
          <ul className="footerLinksContainer" style={{ marginTop: 39.2 }}>
            {footerLinksMobile[1].map(link => (
              <li key={link.title} className="footerLinkListItem">
                <a className="footerLink" target="_blank" href={link.href}>
                  <span className="footerLinkText">{link.title}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div key="eu-sol" className="anpcSalContainer visibleOnTabletWithFlex">
        <a
          className=""
          target="_blank"
          href="http://ec.europa.eu/consumers/odr"
        >
          <StaticImage
            width={250}
            className="anpcSal"
            src="../images/anpc-sol.webp"
            alt="A.N.P.C. - S.O.L."
          />
        </a>
      </div>
      <div key="anpc-sal" className="anpcSalContainer visibleOnTabletWithFlex">
        <a className="" target="_blank" href="https://anpc.ro/ce-este-sal/">
          <StaticImage
            width={250}
            // height={62}
            className="anpcSal"
            src="../images/anpc-sal.webp"
            alt="A.N.P.C. - CE ESTE SAL?"
          />
        </a>
      </div>
      <div className="anpcSalContainer footerCopyRightText visibleOnTabletWithFlex">
        {`© Gazmir Iași ${new Date().getFullYear()}`}
      </div>
    </div>
  )
}
