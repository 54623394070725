import React from "react"
import "./style.css"
import Spinner from "../Spinner"

type PropTypes = {
  infoMsg: string | null
  containerExtraClassName?: string
  textExtraClassName?: string
}
export default function InfoLoading({
  infoMsg,
  containerExtraClassName = "",
  textExtraClassName = "",
}: PropTypes) {
  if (infoMsg)
    return (
      <div className={"infoLoadingContainer " + containerExtraClassName}>
        <Spinner
          containerClassName="appInfoLoadingContainer"
          show={infoMsg === "Vă rugăm așteptați"}
        />
        <div className={"infoLoadingText " + textExtraClassName}>{infoMsg}</div>
      </div>
    )
  else return null
}
