import React from "react"
import userBlurredBackground from "../Hooks/useBlurredBackground"
import { useStopBodyScrollingWithCondition } from "../Hooks/useStopBodyScrollingWithCondition"
import "./styles.css"
import { InfoMsgType } from "../InfoMsg"
import PopupStatusIcon from "./PopupStatusIcon"
import Spinner from "../Spinner"
import { PopupType } from "../eventDispatchers/popupDispatcher"

export type PopUpState =
  | {
      showPopup: false
    }
  | {
      type: PopupType
      showPopup: true
      title: string
      infoMsg: InfoMsgType
    }

type PropTypes = {
  querySelectorForBlurredBackground: string
  querySelectorForStopScrolling: string
  popupState: PopUpState
  titleExtraClassName?: string
}

export default function PopupLayout({
  querySelectorForBlurredBackground,
  querySelectorForStopScrolling,
  popupState,
  titleExtraClassName = "",
}: PropTypes) {
  userBlurredBackground({
    querySelector: querySelectorForBlurredBackground,
    showPopup: popupState.showPopup,
  })

  useStopBodyScrollingWithCondition({
    querySelector: querySelectorForStopScrolling,
    shouldStopScrolling: popupState.showPopup,
  })

  if (popupState.showPopup)
    return (
      <div className="popupLayout">
        <div className="popupContainer">
          <PopupStatusIcon infoMsg={popupState.infoMsg} />
          <div className="text">
            {popupState.title != null && (
              <div className={`popupTitle ${titleExtraClassName}`}>
                {popupState.title}
              </div>
            )}
            {popupState.infoMsg != null && popupState.infoMsg.msg != null && (
              <div className="popupMsg">{popupState.infoMsg.msg}</div>
            )}
          </div>
          <Spinner
            containerClassName="spinner"
            show={popupState.infoMsg != null && popupState.infoMsg.isLoading}
          />
        </div>
      </div>
    )
  else return null
}
