import { Link, navigate } from "gatsby"
import React, { useState } from "react"
import { colors } from "../../styles/globals"
import svgs from "./svgs"
import "./style.css"

export default function UsefullInfo({
  title,
  items,
  expanded,
  toggleShowMoreInfo,
  containerStyle,
}) {
  return (
    <div className="usefullInfoContainer">
      <div className="usefullInfoHeader" onClick={toggleShowMoreInfo}>
        <div className="usefullInfoTitle">
          {title}
          <div
            className="usefullInfoHeaderIcon"
            style={{
              transform: `rotate(${expanded ? "0deg" : "90deg"})`,
            }}
          >
            {/* {svgs.showIcon} */}
          </div>
        </div>
      </div>

      {expanded && (
        <div className="usefullInfoItemsContainer">
          {items.map(item =>
            item.link.includes("http") ? (
              <a
                key={item.title}
                className="usefullInfoItem"
                // style={styles.item}
                href={item.link}
                target="_blank"
              >
                {item.title}
              </a>
            ) : (
              <Link
                key={item.title}
                className="usefullInfoItem"
                // style={styles.item}
                to={item.link}
              >
                {item.title}
              </Link>
            )
          )}
        </div>
      )}
    </div>
  )
}
