import { getToken } from "../services/keepAuth"
import { apiBase, checkStatus } from "./utils"

const baseUrl = `${apiBase}/api/doveziRevizii`

// export function getTarifeRevizii() {
//   return customGet(baseUrl)
// }

export function postDovadaRevizie(data) {
  const loginData = {
    method: "post",
    headers: {
      azza: getToken(),
      Accept: "application/json",
    },
    body: data,
  }
  return fetch(`${baseUrl}`, loginData).then(checkStatus)
}
// export function putNotification(item) {
//   return customPut(`${baseUrl}/${item._id}`, item)
// }

// export function putReadAll(ids) {
//   return customPut(`${baseUrl}/update/readAll`, ids)
// }

// export function putDeleteAll(ids) {
//   return customPut(`${baseUrl}/update/deleteAll`, ids)
// }
