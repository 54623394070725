import { Link } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import { colors } from "../../styles/globals"
import DashMenuItem from "./DashMenuItem"
import svgs from "./svgs"
import "./style.css"

export default function SideMenu({ location }) {
  const [showSubMenuFacturi, setShowSubMenuFacturi] = useState(false)
  const [showContentSubMenuFacturi, setShowContentSubMenuFacturi] =
    useState(false)

  const toggleSubMenuFacturi = () => {
    setShowSubMenuFacturi(oldValue => !oldValue)
    // setShowContentSubMenuFacturi(oldValue => !oldValue)
  }

  const showSubMenuFacturiTimeout = useRef()
  const showContentSubMenuFacturiTimeout = useRef()

  const onMouseEnter = () => {
    // console.log("ON MOUSE ENTER", showContentSubMenuFacturiTimeout.current)
    clearTimeout(showContentSubMenuFacturiTimeout.current)
    setShowContentSubMenuFacturi(true)
    setTimeout(() => {
      setShowSubMenuFacturi(true)
    }, 1)
  }

  const onMouseLeave = () => {
    // console.log("ON MOUSE LEAVE")
    setShowSubMenuFacturi(false)
    showContentSubMenuFacturiTimeout.current = setTimeout(() => {
      // console.log("Executing timeout")
      setShowContentSubMenuFacturi(false)
    }, 400)
  }

  useEffect(() => {
    // console.log("RUNNING USE EFFECT")
    if (location.pathname && location.pathname.includes("facturi")) {
      clearTimeout(showContentSubMenuFacturiTimeout.current)
      setShowContentSubMenuFacturi(true)
    } else {
      showContentSubMenuFacturiTimeout.current = setTimeout(() => {
        setShowContentSubMenuFacturi(false)
      }, 400)
    }
  }, [location])

  return (
    <div className="sideMenuContainer">
      {/* <DashMenuItem
        to="/app/facturi"
        svg={svgs.facturiRestanteIcon}
        menuText="Facturi restante"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        isExpanded={
          showSubMenuFacturi ||
          (location.pathname && location.pathname.includes("facturi"))
        }
        partiallyActive={location.pathname.includes("/app/facturi")}
      >
        {(showContentSubMenuFacturi ||
          (location.pathname && location.pathname.includes("facturi"))) && (
          <div
            className={
              "sideMenuSubMenuContainer" +
              (showSubMenuFacturi ||
              (location.pathname && location.pathname.includes("facturi"))
                ? " sideMenuSubMenuContainerExpanded"
                : "")
            }
          >
            <Link
              tabIndex={0}
              to={"/app/facturi/achitate"}
              className="subMenuHover sideMenuItemContainer sideMenuSubItemContainer"
              activeClassName="sideMenuSubItemContainerActive"
            >
              <span className="sideMenuIcon"> {svgs.facturi}</span>
              <div className="sideMenuText">Facturi achitate</div>
            </Link>
            <Link
              tabIndex={0}
              to={"/app/facturi/fisaClient"}
              className="subMenuHover sideMenuItemContainer sideMenuSubItemContainer"
              activeClassName="sideMenuSubItemContainerActive"
            >
              <span className="sideMenuIcon"> {svgs.fisaClient}</span>
              <div className="sideMenuText">Fișă client</div>
            </Link>
          </div>
        )}
      </DashMenuItem> */}
      <DashMenuItem
        to="/app/facturi"
        svg={svgs.facturiRestanteIcon}
        menuText="Facturi restante"
      />
      <DashMenuItem
        to="/app/facturi/achitate"
        svg={svgs.facturi}
        menuText="Facturi achitate"
      />
      <DashMenuItem
        to="/app/facturi/fisaClient"
        svg={svgs.fisaClient}
        menuText="Fișă client"
      />
      {/* <DashMenuItem
        to="/app/preavize/"
        svg={svgs.preavize}
        menuText="Preavize"
      /> */}
      <DashMenuItem
        to="/app/dateConsum"
        svg={svgs.dateConsum}
        menuText="Date de consum"
      />
      <DashMenuItem
        to="/app/autocitire"
        svg={svgs.autocitireIcon}
        menuText="Autocitire"
      />
      {/* <DashMenuItem
        to="/app/revizii/"
        svg={svgs.revizii}
        menuText="Revizii/V.T.P."
      /> */}
      {/* <DashMenuItem
        to="/app/notificari/"
        svg={svgs.notificari}
        menuText="Notificări"
      /> */}
      {/* <DashMenuItem
        to="/app/fisaClient"
        svg={svgs.fisaClient}
        menuText="Fișă de client"
      /> */}
      {/* <DashMenuItem
        to="/app/setariTehnice"
        svg={svgs.setari}
        menuText="Setări tehnice"
      /> */}
    </div>
  )
}
