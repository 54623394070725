import { Link } from "gatsby"
import React, { PropsWithChildren } from "react"
import "./style.css"

type PropTypes = PropsWithChildren & {
  to: string
  svg: React.ReactNode
  menuText: string
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  partiallyActive?: boolean
  isExpanded?: boolean
  containerClassName?: string
}

export default function DashMenuItem({
  children,
  to,
  svg,
  menuText,
  onMouseEnter,
  onMouseLeave,
  partiallyActive = false,
  isExpanded = false,
  containerClassName = "",
}: PropTypes) {
  return (
    <div
      className={containerClassName}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Link
        tabIndex={0}
        to={to}
        className={
          "menuHover sideMenuItemContainer" +
          (partiallyActive ? " sideMenuItemContainerPartiallyActive" : "")
        }
        activeClassName={"sideMenuItemContainerActive"}

        // partiallyActive="menuItemPartiallyActive"
        // partiallyActive={partiallyActive}
      >
        <span className="sideMenuIcon"> {svg}</span>
        <div className="sideMenuText">{menuText}</div>
        {onMouseEnter && (
          <div
            className="sideMenuExpendIcon"
            style={{
              transform: `rotate(${isExpanded ? "0deg" : "-90deg"})`,
            }}
          >
            <svg
              viewBox="0 0 12 12"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 12L12 0H0L6 12Z"
                // fill={children ? colors.orange : colors.white}
                fill="currentColor"
              />
            </svg>
          </div>
        )}
      </Link>
      {children}
    </div>
  )
}
