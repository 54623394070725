import { Router } from "@reach/router"
import React, { useState } from "react"
import AutoCitirePage from "../components/app/Autocitire"
import DateConsumPage from "../components/app/dateConsum"
import FacturiPage from "../components/app/facturi"
import FacturiAchitatePage from "../components/app/facturi/facturiAchitate"
import FisaClientPage from "../components/app/facturi/fisaClient"
import HomeDashPage from "../components/app/home"
import NotificariPage from "../components/app/notificari"
import PreavizePage from "../components/app/preavize"
import ReviziiPage from "../components/app/revizii"
import SetariAdministrativePage from "../components/app/setari"
import DashPage from "../components/DashPage"
import PageWithBackgroundImage from "../components/pageWithBackgroundImage"
import useScrollRestorationToTop from "../components/Hooks/useScrollRestorationToTop"
import { navigate } from "gatsby-link"
import VerificareInfoClientPopup from "../components/verificareInfoClient"
import { getUserInfo } from "../utils/services/keepAuth"
import ContFaraDatePage from "../components/app/contFaraDate/indext"
import { PageProps } from "gatsby"

export default function App({ location }: PageProps) {
  const [showPaymentRedirectLoading, setShowPaymentRedirectLoading] =
    useState(false)

  React.useEffect(() => {
    const pageShowListener = (event: PageTransitionEvent) => {
      if (event.persisted) {
        setShowPaymentRedirectLoading(false)
        console.log("This page was restored from the bfcache.")
      } else {
        console.log("This page was loaded normally.")
      }
    }

    window.addEventListener("pageshow", pageShowListener)
    return () => {
      window.removeEventListener("pageshow", pageShowListener)
    }
  }, [])

  React.useEffect(() => {
    const handleResize = () => {
      console.log(location.pathname)
      if (
        window.innerWidth > 850 &&
        location.pathname != null &&
        location.pathname.includes("/app/home") &&
        getUserInfo() &&
        getUserInfo().denumire
      ) {
        navigate("/app/facturi")
      }
    }

    window.addEventListener("resize", handleResize)

    handleResize()

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [location.pathname])

  useScrollRestorationToTop("#root", location.pathname ?? "")

  return (
    <PageWithBackgroundImage
      location={location}
      showPaymentRedirectLoading={showPaymentRedirectLoading}
    >
      <VerificareInfoClientPopup />
      <DashPage
        location={location}
        setShowPaymentRedirectLoading={setShowPaymentRedirectLoading}
      >
        <Router basepath="/app/">
          <HomeDashPage
            path="/home/"
            setShowPaymentRedirectLoading={setShowPaymentRedirectLoading}
          />
          <FacturiPage
            path="/facturi/"
            location={location}
            setShowPaymentRedirectLoading={setShowPaymentRedirectLoading}
          />
          <PreavizePage
            path="/preavize/"
            location={location}
            setShowPaymentRedirectLoading={setShowPaymentRedirectLoading}
          />
          <DateConsumPage path="/dateConsum/" location={location} />
          <ReviziiPage
            path="/revizii/"
            location={location}
            setShowPaymentRedirectLoading={setShowPaymentRedirectLoading}
          />
          <NotificariPage path="/notificari/" location={location} />
          <FisaClientPage path="/facturi/fisaClient/" location={location} />
          <FacturiAchitatePage path="/facturi/achitate/" location={location} />
          <SetariAdministrativePage path="/contulMeu/" location={location} />
          <AutoCitirePage path="/autocitire" />
          <ContFaraDatePage path="/contFaraDate" location={location} />
        </Router>
      </DashPage>
    </PageWithBackgroundImage>
  )
}
