import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import svgs from "../../components/Dash/svgs"
import NotificariItem from "../../components/Notificari/NotificariItem"
import Spinner from "../../components/Spinner"
import { colors, generalStyles } from "../../styles/globals"
import { dateToDesiredFormat, logOutUserAndNavigateHome } from "../../utils"
import {
  getAllNotificari,
  putDeleteAll,
  putNotification,
  putReadAll,
} from "../../utils/api/notificari"
import { getUserInfo } from "../../utils/services/keepAuth"
import TemplatePage from "../TemplatePage"

import "./style.css"

export default function NotificariPage({ location }) {
  const [checkAll, setCheckAll] = useState(false)
  const [notificari, setNotificari] = useState(null)
  const [infoMsg, setInfoMsg] = useState("Vă rugăm așteptați")
  const [notificareIndex, setNotificareIndex] = useState(-1)
  // const notificariSelectate = useRef([]);

  const toggleCheckAll = () => {
    if (!checkAll)
      setNotificari(oldNotificari =>
        oldNotificari.map(item => ({ ...item, checked: true }))
      )
    else
      setNotificari(oldNotificari =>
        oldNotificari.map(item => ({ ...item, checked: false }))
      )
    setCheckAll(oldValue => !oldValue)
  }

  const toggleCheck = index => {
    // console.log("Togle check for index: ", index)
    setNotificari(oldNotificari =>
      oldNotificari.map((item, indexArray) =>
        index === indexArray ? { ...item, checked: !item.checked } : item
      )
    )
  }

  const notificationRead = async item => {
    item.citita = true
    try {
      const putResponse = await putNotification(item)
      // console.log(putResponse)
    } catch (error) {
      console.log(error)
    }
  }

  const sendReadAllSelected = async () => {
    const ids = []
    for (var i = 0; i < notificari.length; i++)
      if (notificari[i].checked) ids.push(notificari[i]._id)
    setNotificari(oldNotificari =>
      oldNotificari.map(item =>
        item.checked ? { ...item, citita: true } : item
      )
    )
    try {
      // console.log(ids)
      const putResponse = await putReadAll({ ids })
      // console.log(putResponse)
    } catch (error) {
      console.log(error)
    }
  }

  const sendDeleteAllSelected = async () => {
    const ids = []
    for (var i = 0; i < notificari.length; i++)
      if (notificari[i].checked) ids.push(notificari[i]._id)
    setNotificari(oldNotificari => oldNotificari.filter(item => !item.checked))
    try {
      const putResponse = await putDeleteAll({ ids })
      // console.log(putResponse)
    } catch (error) {
      console.log(error)
    }
  }

  const goToNextNotification = () => {
    setNotificareIndex(oldValue => oldValue + 1)
    if (!notificari[notificareIndex + 1].citita)
      notificationRead(notificari[notificareIndex + 1])
  }
  const goToPrevioustNotification = () => {
    setNotificareIndex(oldValue => oldValue - 1)
    if (!notificari[notificareIndex - 1].citita)
      notificationRead(notificari[notificareIndex - 1])
  }
  // setState(no)

  const goShowNotificare = index => {
    notificationRead(notificari[index])
    setNotificareIndex(index)
  }

  useEffect(() => {
    if (!getUserInfo()) navigate("/")
    const getNotificari = async () => {
      try {
        const apiResponse = await getAllNotificari()
        if (apiResponse.type) {
          setNotificari(apiResponse.data)
          setInfoMsg(null)
        } else
          setInfoMsg(
            "A apărut o eroare la preluarea mesajelor, vă rugăm reîncercați"
          )
      } catch (error) {
        if (error?.statusCode === 403) logOutUserAndNavigateHome()
        setInfoMsg(
          "A apărut o eroare la preluarea mesajelor, vă rugăm reîncercați"
        )
      }
    }
    getNotificari()
  }, [])

  return (
    // <div style={{ ...generalStyles.fullContainer, overflowY: "none" }}>
    //   <div style={generalStyles.screenContainer}>
    //     <div style={styles.pageHeader}>
    //       <div
    //         style={{
    //           ...styles.previousIcon,
    //           cursor: notificareIndex > 0 ? "pointer" : "auto",
    //         }}
    //         onClick={
    //           notificareIndex > 0 ? goToPrevioustNotification : undefined
    //         }
    //       >
    //         {notificareIndex > 0 ? svgs.previous : undefined}
    //       </div>
    //       <div style={styles.pageTitle}>Notificări</div>
    //       <div
    //         style={{
    //           ...styles.nextIcon,
    //           cursor:
    //             notificareIndex !== -1 &&
    //             notificari &&
    //             notificareIndex < notificari.length - 1
    //               ? "pointer"
    //               : "auto",
    //         }}
    //         onClick={
    //           notificareIndex !== -1 &&
    //           notificari &&
    //           notificareIndex < notificari.length - 1
    //             ? goToNextNotification
    //             : undefined
    //         }
    //       >
    //         {notificareIndex !== -1 &&
    //         notificari &&
    //         notificareIndex < notificari.length - 1
    //           ? svgs.next
    //           : undefined}
    //       </div>
    //     </div>

    <TemplatePage
      showDash
      location={location}
      title="Notificări"
      showMobileMenu={false}
      additionalTitleClassName="bgGrayOnTablet"
      // showMobileMenu
    >
      {notificareIndex === -1 && (
        <>
          <div className="notificariActionsContainer">
            <div className="notificariActionsItem" onClick={toggleCheckAll}>
              <div className="notificariActionsIconContainer">
                <input
                  className="notificariActionsCheckInput"
                  type="checkbox"
                  checked={checkAll}
                />
              </div>

              <div
                className="tabelHeaderDinamicText notificariActionsText"
                data-desktop-label="Selectează toate mesajele"
                data-mobile-label="Selectează"
              ></div>
            </div>
            <div
              className="notificariActionsItem"
              onClick={sendReadAllSelected}
            >
              <div className="notificariActionsIconContainer">
                {svgs.readAll}
              </div>
              <div
                className="tabelHeaderDinamicText notificariActionsText"
                data-desktop-label="Marchează drept citite"
                data-mobile-label="Vizualizat"
              ></div>
            </div>
            <div
              className="notificariActionsItem"
              onClick={sendDeleteAllSelected}
            >
              <div className="notificariActionsIconContainer">
                {svgs.delete}
              </div>
              <div
                className="tabelHeaderDinamicText notificariActionsText"
                data-desktop-label="Șterge mesajele"
                data-mobile-label="Șterge"
              ></div>
            </div>
          </div>
          <div style={styles.notificariContainer}>
            <Spinner
              containerStyle={styles.spinnerContainer}
              show={infoMsg === "Vă rugăm așteptați"}
            />
            {infoMsg && <div style={styles.infoMsg}>{infoMsg}</div>}
            {notificari && notificari.length === 0 && (
              <div style={styles.infoMsg}>Nu aveți nici un mesaj</div>
            )}
            {notificari &&
              notificari.length > 0 &&
              notificari.map((item, index) => (
                <NotificariItem
                  key={item._id}
                  item={item}
                  index={index}
                  checked={item.checked}
                  onCheck={toggleCheck}
                  onPress={goShowNotificare}
                />
              ))}
          </div>
        </>
      )}
      {notificareIndex > -1 && (
        <>
          <div style={styles.rowContainer}>
            <div style={styles.titleContainer}>
              <div
                style={{
                  ...styles.titleText,
                  fontWeight: "300",
                }}
              >
                {notificari[notificareIndex].titlu}
              </div>
            </div>
            <div style={styles.dateContainer}>
              <div
                style={styles.viewItemCloseIcon}
                onClick={() => setNotificareIndex(-1)}
              >
                {svgs.closeIcon}
              </div>
              <div
                style={{
                  ...styles.dateText,
                  fontWeight: "300",
                }}
              >
                {dateToDesiredFormat(
                  new Date(notificari[notificareIndex].created_at)
                )}
              </div>
            </div>
          </div>
          <div style={styles.viewItemContent}>
            {notificari.map(item => notificari[notificareIndex].continut)}
          </div>
        </>
      )}
    </TemplatePage>
  )
}

const styles = {
  pageHeader: {
    // width: "100%",
    height: 80,
    backgroundColor: colors.main,
    float: "left",
    width: "100%",
  },
  previousIcon: {
    float: "left",
    marginTop: 28,
    width: 24,
    height: 24,
    // marginLeft: 450,
    width: "33%",
    fill: colors.white,
  },
  nextIcon: {
    float: "left",
    marginTop: 28,
    width: 24,
    height: 24,
    // marginRight: 412,
    width: "33%",
    fill: colors.white,
  },
  pageTitle: {
    float: "left",
    width: "34%",
    textAlign: "center",
    fontSize: 32,
    fontWeight: 700,
    color: colors.white,
    lineHeight: "80px",
    marginLeft: 20,
  },
  actionsContainer: {
    height: 80,
  },
  actionItem: {
    float: "left",
    height: 80,
    width: "33%",
    cursor: "pointer",
    lineHeight: "80px",
    // border: "1px solid black",
  },
  iconContainer: {
    // float: "left",
    display: "inline-block",
    height: 40,
    width: 40,
    // marginTop: 20,
    marginLeft: 24,
    verticalAlign: "middle",
  },
  checkBox: {
    float: "left",
    marginTop: 2,
    width: 16,
    height: 16,
    cursor: "pointer",
    // marginTop: 11,
    // border: "1px solid rgba(0, 47, 73, 0.3)",
    // appearance: "none",
    // boxSizing: "border-box",
    borderRadius: 2,
    border: `1px solid ${colors.main}`,
  },
  actionText: {
    display: "inline-block",
    width: "calc(100% - 90px)",
    fontSize: 20,
    color: colors.main,
    // marginTop: 30,
    lineHeight: "normal",
    verticalAlign: "middle",
  },
  notificariContainer: {
    width: "100%",
    height: "calc(100% - 160px)",
    overflowY: "auto",
  },
  spinnerContainer: {
    height: 60,
    width: 60,
    margin: "35% auto",
  },
  infoMsg: {
    padding: "30px 60px",
    // marginTop: 32,
    textAlign: "center",
    fontSize: 18,
    color: colors.main,
  },
  rowContainer: {
    float: "left",
    height: 80,
    width: "100%",
    border: "1px solid rgba(0, 47, 73, 0.5)",
  },
  titleContainer: {
    float: "left",
    width: "66%",
    height: 80,
  },
  titleText: {
    width: "90%",
    margin: "30px auto",
    height: 20,
    overflow: "hidden",
    fontSize: 20,
    lineHeight: "20px",
    color: colors.main,
  },
  dateContainer: {
    float: "left",
    width: "34%",
    height: 80,
    borderLeft: "1px solid rgba(0, 47, 73, 0.5)",
  },
  dateText: {
    height: 20,
    width: 110,
    margin: "30px auto",
    fontSize: 20,
    lineHeight: "20px",
    color: colors.main,
  },
  viewItemContent: {
    width: "100%",
    height: "100%",
    overflowY: "auto",
    padding: "40px 40px",
    textAlign: "justify",
    fontSize: 16,
    color: colors.main,
  },
  viewItemCloseIcon: {
    float: "right",
    width: 16,
    height: 16,
    marginTop: 32,
    marginRight: 20,
    cursor: "pointer",
    fill: colors.main,
  },
}
