import React, { useEffect, useState } from "react"
import { colors } from "../../styles/globals"
import { dateToDesiredFormat } from "../../utils"

export default function NotificariItem({
  item,
  index,
  checked = false,
  onCheck,
  onPress,
}) {
  const [isChecked, setIsChecked] = useState(checked)

  const toggleCheck = () => {
    // setIsChecked(oldValue => !oldValue)
    onCheck(index)
  }

  const sendOnPress = () => {
    onPress(index)
  }

  useEffect(() => {
    if (checked !== isChecked) setIsChecked(checked)
  }, [checked])

  // console.log(item)

  return (
    <div style={styles.rowContainer}>
      <div style={styles.titleContainer}>
        <div style={styles.iconContainer}>
          <input
            style={{
              ...styles.checkBox,
              border: `${item.citita ? 1 : 3}px solid ${colors.main}`,
            }}
            type="checkbox"
            checked={isChecked}
            onClick={toggleCheck}
          />
        </div>
        <div
          style={{
            ...styles.titleText,
            fontWeight: item.citita ? "300" : "700",
            cursor: "pointer",
          }}
          onClick={sendOnPress}
        >
          {item.titlu}
        </div>
      </div>
      <div style={styles.dateContainer}>
        <div
          style={{
            ...styles.dateText,
            fontWeight: item.citita ? "300" : "700",
            cursor: "pointer",
          }}
          onClick={sendOnPress}
        >
          {dateToDesiredFormat(new Date(item.created_at))}
        </div>
      </div>
    </div>
  )
}

const styles = {
  rowContainer: {
    height: 80,
    width: "100%",
    borderTop: "1px solid rgba(0, 47, 73, 0.5)",
  },
  titleContainer: {
    float: "left",
    width: "66%",
    height: 80,
    lineHeight: "80px",
  },
  titleText: {
    display: "inline-block",
    width: "calc(100% - 90px)",
    // margin: "30px auto",
    // height: 20,
    overflow: "hidden",
    fontSize: 20,
    lineHeight: "normal",
    verticalAlign: "middle",

    color: colors.main,
  },
  dateContainer: {
    float: "left",
    width: "34%",
    height: 80,
    borderLeft: "1px solid rgba(0, 47, 73, 0.5)",
    textAlign: "center",
    lineHeight: "80px",
  },
  dateText: {
    display: "inline-block",
    width: "100%",
    fontSize: 20,
    color: colors.main,
    lineHeight: "normal",
    verticalAlign: "middle",
  },
  checkBox: {
    width: 16,
    height: 16,
    // marginTop: 11,
    // appearance: "none",
    borderRadius: 2,
  },
  iconContainer: {
    float: "left",
    height: 40,
    width: 40,
    // marginTop: 20,
    marginLeft: 24,
  },
}
