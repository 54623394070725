import React from "react"

type PropTypes = {
  querySelector?: string
  shouldStopScrolling: boolean
}
export function useStopBodyScrollingWithCondition({
  querySelector = ".pageWithBackground",
  shouldStopScrolling,
}: PropTypes) {
  React.useEffect(() => {
    return () => {
      const pageWithBackground = document.querySelector(querySelector)
      if (pageWithBackground)
        pageWithBackground.classList.remove("stopScrolling")
    }
  })

  React.useEffect(() => {
    // if (document.querySelector(querySelector)[0])
    //   document.querySelector(querySelector)[0].className =
    //     "stopScrolling pageWithBackground"
    // console.log(document.location.pathname)
    // if(document?.location.pathname)
    if (shouldStopScrolling) {
      const pageWithBackground = document.querySelector(querySelector)
      if (pageWithBackground) pageWithBackground.classList.add("stopScrolling")
    } else {
      const pageWithBackground = document.querySelector(querySelector)
      if (pageWithBackground)
        pageWithBackground.classList.remove("stopScrolling")
    }
  }, [shouldStopScrolling])
}
