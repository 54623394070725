import React from "react"
import PageInput from "./PageInput"
import svgs from "../../Dash/svgs"

type PropTypes = {
  type?: string
  name: string
  label: string
  placeholder: string
  value: string
  onClick?: () => void
  onChange?: (value: string, name: string) => void
  errorTest?: (value: string) => boolean
  errorMessage?: string
  disabled?: boolean
  tooltipText?: string
}

export default function SettingsInputRow({
  type = "text",
  name,
  label,
  placeholder,
  value,
  onClick,
  onChange,
  errorTest,
  errorMessage,
  disabled = true,
  tooltipText,
}: PropTypes) {
  return (
    <div className={"saRow" + (type === "checkbox" ? " saRowCheckBox" : "")}>
      <div className="saRowLabel">{label}</div>
      <div className="saRowInput">
        <PageInput
          type={type}
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          value={value}
          errorTest={errorTest}
          errorMessage={errorMessage}
          disabled={disabled}
        />
        <div
          className={"saRowInputIcon " + (tooltipText ? "tooltip " : "")}
          //   style={{ ...styles.editIcon, marginTop: type !== "checkbox" ? 0 : 4 }}
          onClick={onClick}
        >
          {type !== "checkbox"
            ? onClick != null
              ? svgs.editIcon
              : null
            : svgs.info}
          {tooltipText && <span className="tooltiptext">{tooltipText}</span>}
        </div>
      </div>
    </div>
  )
}
