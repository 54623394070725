import {
  ApiResponse,
  apiBase,
  customDelete,
  customPost,
  customPut,
} from "./utils"

const baseUrl = `${apiBase}/api/userInfo`

// export function getTarifeRevizii() {
//   return customGet(baseUrl)
// }

type PutUserInfoBody = {
  denumire?: string
  telefon?: string
  telUrgente?: string
  emailSecundar?: string
  adresaClc?: string
}
export function putUserInfo(
  bodyData: PutUserInfoBody
): Promise<ApiResponse<null>> {
  return customPut(baseUrl, bodyData)
}

export function putValideUserInfo() {
  return customPut(`${baseUrl}/validateInfo`, {})
}

interface PostValidatePassword extends ApiResponse<null> {
  reason?: 0 | 1 | 2
}

export function postValidatePassword(data: {
  parola: string
}): Promise<PostValidatePassword> {
  return customPost(`${baseUrl}/validatePassword`, data)
}

type PostVerifyEmailForSwapBody = {
  emailNou: string
  parola: string
}

export function postVerifyEmailForSwap(
  data: PostVerifyEmailForSwapBody
): Promise<ApiResponse<null>> {
  return customPost(`${baseUrl}/verifyEmailForSwap`, data)
}

type PostCheckVerificationCodeAndSwapEmailBody = {
  emailNou: string
  emailVerificationCode: string
}

export function postCheckVerificationCodeAndSwapEmail(
  data: PostCheckVerificationCodeAndSwapEmailBody
): Promise<ApiResponse<null>> {
  return customPost(`${baseUrl}/checkVerificationCodeAndSwapEmail`, data)
}

type PostChangeEmailBody = {}

type PostResetPasswordBody = {
  parolaVeche: string
  parolaNoua: string
}

export function postResetPassword(
  data: PostResetPasswordBody
): Promise<ApiResponse<null>> {
  return customPost(`${baseUrl}/changeUserPassword`, data)
}

type PutUserNotificationsInfoBody =
  | {
      facturaElectronica: boolean
    }
  | { notificareSmsFactura: boolean }

export function putUserNotificationsInfo(
  data: PutUserNotificationsInfoBody
): Promise<ApiResponse<null>> {
  return customPut(`${baseUrl}/userNotificationInfo`, data)
}

type PutDeleteUserAccount = {
  parola: string
}

interface DeleteUserAccountResponse extends ApiResponse<null> {
  passwordMismatch?: boolean
}

export function deleteUserAccount(
  data: PutDeleteUserAccount
): Promise<DeleteUserAccountResponse> {
  return customDelete(`${baseUrl}`, data)
}

// export function putNotification(item) {
//   return customPut(`${baseUrl}/${item._id}`, item)
// }

// export function putReadAll(ids) {
//   return customPut(`${baseUrl}/update/readAll`, ids)
// }

// export function putDeleteAll(ids) {
//   return customPut(`${baseUrl}/update/deleteAll`, ids)
// }
