import React, { useEffect, useRef, useState } from "react"
import UsefullInfo from "../../../components/Dash/UsefullInfo"
import Spinner from "../../../components/Spinner"
import { colors, generalStyles } from "../../../styles/globals"
import { isUserCasnic } from "../../../utils"
import { getAllRevizii } from "../../../utils/api/revizii"
import { getTarifeRevizii } from "../../../utils/api/tarifeRevizii"
import { getUserInfo } from "../../../utils/services/keepAuth"
import TemplatePage from "../../TemplatePage"
import IncarcaDovadaRevizie from "./incarcaDovadaRevizie"
import ProgramareRevizie from "./programareRevizie"
import TabelRevizii from "./TabelRevizii"

const styles = {
  tabBarButton: {
    float: "left",
    width: 480,
    height: 56,
    marginLeft: 40,
    marginTop: 12,
    color: colors.white,
    fontSize: 32,
    lineHeight: "56px",
    textAlign: "center",
  },
}

const infoItem = [
  {
    title: "Ce este Revizia Tehnica și când trebuie făcută?",
    link: "/infoUtile/faqs/generale?idSelectedFaq=61b1cdfd4db367c6fa97d934",
  },
  {
    title: "Ce este Verificarea Tehnică și când trebuie făcută?",
    link: "/infoUtile/faqs/generale?idSelectedFaq=61b1cfff4db367c6fa97d935",
  },
  // { title: "La cât timp trebuie efectuată Revizia Tehnică ?" },
  // { title: "La cât timp trebuie efectuată Verificarea Tehnică Periodică ?" },
  // { title: "Care este tariful de furnizare a gazelor naturale?" },
]

export default function ReviziiPage({
  location,
  setShowPaymentRedirectLoading,
}) {
  const [revizii, setRevizii] = useState(null)
  const [infoMsg, setInfoMsg] = useState(null)
  const [revizieProgramare, setRevizieProgramare] = useState(null)
  const [revizieDovada, setRevizieDovada] = useState(null)
  const tarifeRef = useRef(null)
  const tarifUnitarRef = useRef(null)

  const [showMoreInfo, setShowMoreInfo] = useState(true)

  const toggleShowMoreInfo = () => {
    setShowMoreInfo(oldValue => !oldValue)
  }

  const programeazate = revizieProgramare => {
    // console.log("TO DO programareRevizie: ", revizieDovada)
    if (revizieProgramare.tipActivitate === 1)
      if (isUserCasnic(getUserInfo()))
        tarifUnitarRef.current = tarifeRef.current.revizieIu.casnic
      else tarifUnitarRef.current = tarifeRef.current.revizieIu.nonCasnic
    else if (isUserCasnic(getUserInfo()))
      tarifUnitarRef.current = tarifeRef.current.vtpIu.casnic
    else tarifUnitarRef.current = tarifeRef.current.vtpIu.nonCasnic
    setRevizieProgramare(revizieProgramare)
  }

  const incaraDovada = item => {
    // console.log("TO DO incarca dovada: ", idRevizie)
    setRevizieDovada(item)
  }

  const hideIncarcaDovada = () => {
    setRevizieDovada(null)
  }

  const hideProgramare = () => {
    setRevizieProgramare(null)
  }

  useEffect(() => {
    const getRevizii = async () => {
      setInfoMsg("Vă rugăm așteptați")
      try {
        const apiResponse = await getAllRevizii()
        const tarifeResponse = await getTarifeRevizii()
        // console.log(tarifeResponse)
        if (apiResponse.type && tarifeResponse.type) {
          tarifeRef.current = tarifeResponse.data
          setRevizii(apiResponse.data)
          setInfoMsg("În curs de dezvoltare")
        } else setInfoMsg("Indisponibil")
      } catch (error) {
        console.log(error)
        setInfoMsg("Indisponibil")
      }
    }
    getRevizii()
  }, [])

  return (
    <>
      <TemplatePage title="Revizii/V.T.P" location={location} showDash>
        {infoMsg && (
          <div
            style={{
              position: "absolute",
              top: 0,
              marginTop: 250,
              textAlign: "center",
              width: "100%",
            }}
          >
            <Spinner
              containerStyle={{
                width: 60,
                height: 60,
                margin: "0 auto",
              }}
              show={infoMsg === "Vă rugăm așteptați"}
            />
            <div
              style={{
                color: colors.main,
                marginTop: 30,
                textAlign: "center",
                fontSize: 20,
                fontWeight: 700,
              }}
            >
              {infoMsg}
            </div>
          </div>
        )}

        {/* <div style={{}}>
          {revizii !== null && (
            <TabelRevizii
              revizii={revizii}
              programeazate={programeazate}
              incaraDovada={incaraDovada}
            />
          )}
        </div> */}
        {/* <UsefullInfo
          title="Informații despre Revizii / V.T.P. :"
          items={infoItem}
          expanded={showMoreInfo}
          toggleShowMoreInfo={toggleShowMoreInfo}
        /> */}
      </TemplatePage>
      <ProgramareRevizie
        revizieProgramare={revizieProgramare}
        tarifUnitar={tarifUnitarRef.current}
        hideModal={hideProgramare}
        setShowPaymentRedirectLoading={setShowPaymentRedirectLoading}
      />
      <IncarcaDovadaRevizie
        revizieDovada={revizieDovada}
        hideModal={hideIncarcaDovada}
        // isCasnic={isUserCasnic(getUserInfo())}
      />
    </>
  )
}
