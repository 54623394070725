import React, { FormEvent, useState } from "react"
import { logOutUserAndNavigateHome } from "../../../utils"
import { deleteUserAccount } from "../../../utils/api/userInfo"
import Button from "../../Button"
import svgs from "../../Dash/svgs"
import FormInput from "../../FormInput"
import InfoMsg, { InfoMsgType } from "../../InfoMsg"

type PropTypes = {
  hideModal: () => void
}

type FormData = {
  parola: string
}

function checkFormData(formData: FormData) {
  return formData.parola.length > 0
}

export default function ModalDeleteAccount({ hideModal }) {
  const [infoMsg, setInfoMsg] = React.useState<InfoMsgType>({
    isLoading: false,
  })
  const [formData, setFormData] = useState<FormData>({
    parola: "",
  })

  const handleInputChange = (value, name) => {
    if (infoMsg.status === "error")
      setInfoMsg({
        isLoading: false,
      })
    setFormData(oldFormData => ({
      ...oldFormData,
      [name]: value,
    }))
  }

  const submitForm = async (event: FormEvent) => {
    event.preventDefault()
    setInfoMsg({
      isLoading: true,
    })

    try {
      const apiResponse = await deleteUserAccount({ parola: formData.parola })
      if (apiResponse.type) {
        setInfoMsg({
          isLoading: false,
          status: "ok",
          msg: "Modificarea a fost salvată cu succes dureaza 2-3 zile să se execute schimbarea. In căteva secunde veți fi delogat!",
        })
        // saveUserInfo({ ...getUserInfo(), facturaElectronica: false })
        // changeUserInfo(false, "facturaElectronica")

        setTimeout(() => {
          logOutUserAndNavigateHome()
          // hideModal();
        }, 4000)
      } else if (apiResponse.passwordMismatch)
        setInfoMsg({
          isLoading: false,
          status: "error",
          msg: "Parola introdusă este greșită!",
        })
      else
        setInfoMsg({
          isLoading: false,
          status: "error",
          msg: "A apărut o eroare de comunicare. Vă rugăm reîncercați.",
        })
    } catch (error) {
      setInfoMsg({
        isLoading: false,
        status: "error",
        msg: "A apărut o eroare de comunicare. Vă rugăm reîncercați.",
      })
      console.log(error)
    }
  }

  // console.log(formData)

  return (
    <div className="popupLayout">
      <div className="popupContainer">
        <div
          className="popUpCloseIcon"
          role="button"
          tabIndex={0}
          // style={styles.loginLogoContainer}
          onClick={hideModal}
        >
          {svgs.closeIcon}
        </div>
        <div className="text">
          <div className="popupTitle popupExtraTitle colorPrimary">
            Ștergere cont ?
          </div>
        </div>
        <div className="popupMsg textAlignLeft">
          Toate informațiile aferente vor dispărea permanent fără posibilitatea
          de a le recupera. Vă rugăm să confirmați faptul că doriți să vă
          ștergeți contul permanent!
        </div>
        <form
          id="deleteUserAccountForm"
          className="newStyleForm"
          onSubmit={submitForm}
        >
          <FormInput
            autoComplete="current-password"
            containerClassName="newStyleInput"
            type="password"
            label="Introdu parolă"
            name="parola"
            onChange={handleInputChange}
            value={formData.parola}
          />

          <InfoMsg containerClassName="row" infoMsg={infoMsg} />
          <div className="row rowButton">
            <Button
              isLoading={infoMsg.isLoading}
              as="button"
              type="submit"
              label="Da, sunt sigur"
              className="newStyleButton"
              aspect="danger"
              disabled={!checkFormData(formData)}
            />
          </div>

          <div className="row rowButton">
            <Button
              as="button"
              type="button"
              label="Nu, renuntă "
              className="newStyleButton"
              aspect="noBackground"
              onClick={hideModal}
            />
          </div>
        </form>
      </div>
    </div>
  )
}
