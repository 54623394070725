import React, { useEffect, useState } from "react"
import { colors } from "../../styles/globals"
import { getAllAnunturi } from "../../utils/api/anunturi"
import { getAllNotificari, putNotification } from "../../utils/api/notificari"
import Spinner from "../Spinner"
import svgs from "./svgs"

export default function NotificariAnunturi() {
  const [isNotificariSelected, setIsNotificariSelected] = useState(true)
  const [notificari, setNotificari] = useState(null)
  const [anunturi, setAnunturi] = useState(null)
  const [notificareIndex, setNotificareIndex] = useState(-1)
  const [anuntIndex, setAnuntIndex] = useState(-1)

  const fetchNotificari = async () => {
    try {
      const notificariResponse = await getAllNotificari()
      // console.log(notificariResponse)
      if (notificariResponse.type) setNotificari(notificariResponse.data)
      else setNotificari("Indisponibil")
    } catch (error) {
      console.log(error)
      setNotificari("Indisponibil")
    }
  }

  const fetchAnunturi = async () => {
    try {
      const notificariResponse = await getAllAnunturi()
      // console.log(notificariResponse)
      if (notificariResponse.type) setAnunturi(notificariResponse.data)
      else setAnunturi("Indisponibil")
    } catch (error) {
      console.log(error)
      setAnunturi("Indisponibil")
    }
  }

  const notificationRead = async item => {
    item.citita = true
    try {
      const putResponse = await putNotification(item)
      // console.log(putResponse)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchNotificari()
    fetchAnunturi()
  }, [])

  const selectAnunturi = () => {
    setIsNotificariSelected(false)
  }

  const selectNotificari = () => {
    setIsNotificariSelected(true)
  }

  const goToNextNotification = () => {
    setNotificareIndex(oldValue => oldValue + 1)
    if (!notificari[notificareIndex + 1].citita)
      notificationRead(notificari[notificareIndex + 1])
  }
  const goToPrevioustNotification = () => {
    setNotificareIndex(oldValue => oldValue - 1)
    if (!notificari[notificareIndex - 1].citita)
      notificationRead(notificari[notificareIndex - 1])
  }

  const goToNextAnunt = () => setAnuntIndex(oldValue => oldValue + 1)
  const goToPrevioustAnunt = () => setAnuntIndex(oldValue => oldValue - 1)

  let notificareComponent
  if (isNotificariSelected && notificareIndex > -1)
    notificareComponent = (
      <div style={styles.viewItemContainer}>
        <div style={styles.viewItemHeader}>
          <div style={styles.viewItemHeaderText}>
            {notificari[notificareIndex].titlu}
          </div>
          <div
            style={styles.viewItemCloseIcon}
            onClick={() => setNotificareIndex(-1)}
          >
            {svgs.closeIcon}
          </div>
        </div>
        <div style={styles.viewItemContent}>
          {notificari[notificareIndex].continut}
        </div>
      </div>
    )
  // {isNotificariSelected &&
  //   notificari &&
  //   notificari.length > 0 &&
  else if (isNotificariSelected && notificari && notificari.map)
    notificareComponent = notificari.map((item, index) => (
      <div
        key={index}
        style={styles.tableItemContainer}
        onClick={() => {
          setNotificareIndex(index)
          notificationRead(item)
        }}
      >
        <div
          style={{
            ...styles.tableItemText,
            fontWeight: item.citita ? "300" : "700",
          }}
        >
          {item.titlu}
        </div>
        <div style={styles.viewItemIcon}>{svgs.notificariAnunturiIcon}</div>
      </div>
    ))
  let anunturiComponent
  if (!isNotificariSelected && anuntIndex > -1)
    anunturiComponent = (
      <div style={styles.viewItemContainer}>
        <div style={styles.viewItemHeader}>
          <div style={styles.viewItemHeaderText}>
            {anunturi[anuntIndex].titlu}
          </div>
          <div
            style={styles.viewItemCloseIcon}
            onClick={() => setAnuntIndex(-1)}
          >
            {svgs.closeIcon}
          </div>
        </div>
        <div
          style={styles.viewItemContent}
          dangerouslySetInnerHTML={{ __html: anunturi[anuntIndex].continut }}
        ></div>
      </div>
    )
  else if (!isNotificariSelected && anunturi && anunturi.map)
    anunturiComponent = anunturi.map((item, index) => (
      <div
        key={index}
        style={styles.tableItemContainer}
        onClick={() => {
          setAnuntIndex(index)
        }}
      >
        <div style={styles.tableItemText}>{item.titlu}</div>
        <div style={styles.viewItemIcon}>{svgs.notificariAnunturiIcon}</div>
      </div>
    ))
  return (
    <div style={styles.mainContainer}>
      <div>
        <div
          className={isNotificariSelected ? undefined : "subMenuHover"}
          style={{
            ...styles.tabContainer,
            backgroundColor: isNotificariSelected ? colors.white : colors.main,
            cursor: isNotificariSelected ? "initial" : "pointer",
          }}
          onClick={isNotificariSelected ? undefined : selectNotificari}
        >
          <div
            style={{
              ...styles.previousIcon,
              cursor: notificareIndex > 0 ? "pointer" : "initial",
            }}
            onClick={
              notificareIndex > 0 ? goToPrevioustNotification : undefined
            }
          >
            {notificareIndex > 0 ? svgs.previous : undefined}
          </div>
          <div
            style={{
              ...styles.tabText,
              color: isNotificariSelected ? colors.main : colors.white,
            }}
          >
            Notificări
          </div>
          <div
            style={{
              ...styles.nextIcon,
              cursor:
                notificareIndex !== -1 &&
                notificareIndex < notificari.length - 1
                  ? "pointer"
                  : "auto",
            }}
            onClick={
              notificareIndex !== -1 &&
              notificareIndex < notificari.length - 1 &&
              isNotificariSelected
                ? goToNextNotification
                : undefined
            }
          >
            {notificareIndex !== -1 &&
            notificareIndex < notificari.length - 1 &&
            isNotificariSelected
              ? svgs.next
              : undefined}
          </div>
        </div>
        <div
          className={isNotificariSelected ? "subMenuHover" : undefined}
          style={{
            ...styles.tabContainer,
            backgroundColor: isNotificariSelected ? colors.main : colors.white,
            cursor: isNotificariSelected ? "pointer" : "initial",
          }}
          onClick={isNotificariSelected ? selectAnunturi : undefined}
        >
          <div
            style={{
              ...styles.previousIcon,
              cursor: anuntIndex > 0 ? "pointer" : "initial",
            }}
            onClick={
              anuntIndex > 0 && !isNotificariSelected
                ? goToPrevioustAnunt
                : undefined
            }
          >
            {anuntIndex > 0 && !isNotificariSelected
              ? svgs.previous
              : undefined}
          </div>
          <div
            style={{
              ...styles.tabText,

              color: isNotificariSelected ? colors.white : colors.main,
            }}
          >
            Anunțuri
          </div>
          <div
            style={{
              ...styles.nextIcon,
              cursor:
                anuntIndex !== -1 && anuntIndex < anunturi.length - 1
                  ? "pointer"
                  : "auto",
            }}
            onClick={
              anuntIndex !== -1 &&
              anuntIndex < anunturi.length - 1 &&
              !isNotificariSelected
                ? goToNextAnunt
                : undefined
            }
          >
            {anuntIndex !== -1 &&
            anuntIndex < anunturi.length - 1 &&
            !isNotificariSelected
              ? svgs.next
              : undefined}
          </div>
        </div>
        <div style={styles.tableContainer}>
          <Spinner containerStyle={styles.spinner} show={notificari === null} />
          {notificareComponent}
          {notificari && isNotificariSelected && notificari.length === 0 && (
            <div
              style={{
                marginTop: 150,
                textAlign: "center",
                lineHeight: "50px",
                fontSize: 24,
                fontWeight: "bold",
                color: colors.main,
              }}
            >
              Nu aveți nici o notificare.
            </div>
          )}
          {anunturiComponent}
        </div>
      </div>
    </div>
  )
}

const styles = {
  mainContainer: {
    position: "absolute",
    top: "40vh",
    right: "1vw",
    backgroundColor: colors.white,
    width: 480,
    height: 504,
  },
  tabContainer: {
    float: "left",
    height: 64,
    width: 240,
  },
  previousIcon: {
    float: "left",
    marginTop: 24,
    width: 16,
    height: 16,
    marginLeft: 34,
    fill: colors.main,
  },
  tabText: {
    float: "left",
    marginLeft: 24,
    textAlign: "center",
    fontSize: 24,
    lineHeight: "64px",
  },
  nextIcon: {
    float: "right",
    marginTop: 24,
    width: 16,
    height: 16,
    marginRight: 34,
    fill: colors.main,
  },
  viewItemContainer: { width: "100%", height: 440 },
  viewItemHeader: {
    width: "100%",
    height: 40,
    borderTop: "1px solid " + colors.textGrey,
    borderBottom: "1px solid " + colors.textGrey,
  },
  viewItemHeaderText: {
    float: "left",
    height: 40,
    width: 360,
    marginLeft: 40,
    fontSize: 20,
    lineHeight: "40px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    color: colors.main,
  },
  viewItemCloseIcon: {
    float: "left",
    width: 16,
    height: 16,
    marginTop: 12,
    marginLeft: 20,
    cursor: "pointer",
    fill: colors.main,
  },
  viewItemContent: {
    width: "100%",
    height: 399,
    overflowY: "auto",
    padding: "20px 40px",
    textAlign: "justify",
    fontSize: 16,
    color: colors.main,
  },
  tableContainer: { overflowY: "auto", width: 480, height: 440 },
  spinner: {
    float: "left",
    width: 60,
    height: 60,
    margin: "190px 0px 0px 210px",
  },
  tableItemContainer: {
    width: 460,
    height: 40,
    borderTop: "1px solid" + colors.textGrey,
    cursor: "pointer",
  },
  tableItemText: {
    float: "left",
    width: 356,
    height: 40,
    color: colors.main,
    fontSize: 20,
    textOverflow: "ellipsis",
    overflow: "hidden",
    lineHeight: "40px",
    marginLeft: 40,
  },
  viewItemIcon: {
    float: "left",
    height: 20,
    width: 20,
    marginLeft: 24,
    marginTop: 10,
  },
}
